import React from "react";
import { Header, Button } from "semantic-ui-react";
import { useAuth0Context } from "@/auth";
import { Loader } from "@/components/common";

interface IProps {
    children?: React.ReactNode;
}

export const ContentPage: React.FC<IProps> = (props) => {
    const { loading, loginWithRedirect, isAuthenticated, user } = useAuth0Context();

    // Redirect to the login page when not signed in properly.
    React.useLayoutEffect(() => {
        if (!loading && !isAuthenticated) {
            setTimeout(loginWithRedirect, 3000);
        }
    }, [loading, isAuthenticated, user]);

    return (
        <>
            {loading ? (
                <Loader display="fullscreen">
                    Welcome!<br />
                    Please wait while your account is validated...
                </Loader>
            ) : (!isAuthenticated) && (
                <div>
                    <Header>Keeyns Management</Header>
                    <div>This page can only be visited when logged in.</div>
                    <div>Please wait while you are redirected or click to continue...</div>
                    <div><br />
                        <Button onClick={() => loginWithRedirect({})} content="Sign in..." />
                    </div>
                </div>
            )}
            {user != null && props.children}
        </>
    );
};
