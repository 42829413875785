import * as React from "react";
import classNames from "classnames";
import { Dropdown, SemanticICONS, Icon, SemanticCOLORS, SemanticSIZES, Divider as SemanticDevider } from "semantic-ui-react";

import styles from "./css/MoreOptions.scss";

interface IProps {
    size?: SemanticSIZES;
    direction?: "left" | "right";
    text?: string | null;
    children?: React.ReactNode;
}

interface IItemProps {
    icon: SemanticICONS;
    iconColor?: SemanticCOLORS;
    label: string;
    isDisabled?: boolean;
    onClick: () => void;
}

const Item: React.FC<IItemProps> = (props) => (
    <Dropdown.Item
        icon={<Icon name={props.icon} color={props.iconColor} />}
        content={props.label}
        onClick={props.isDisabled !== true ? props.onClick : undefined}
        disabled={props.isDisabled}
    />
);

const Divider: React.FC = () => (
    <SemanticDevider />
);

export const MoreOptions: React.FC<IProps> & { Item: typeof Item, Divider: typeof Divider } = (props) => (
    <Dropdown item={true} basic={true} direction={props.direction} button={true}
        text={props.text != null ? props.text : undefined}
        icon={props.text != null ? "dropdown" : "ellipsis horizontal"}
        className={classNames({ icon: props.text == null }, props.size, styles.component)}
    >
        <Dropdown.Menu>
            {props.children}
        </Dropdown.Menu>
    </Dropdown>
);
MoreOptions.Item = Item;
MoreOptions.Divider = Divider;
