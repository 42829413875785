import * as React from "react";
import { useLoggingContext } from "@/contexts/LoggingContext";
import { AxiosError } from "axios";

interface IConfig<TResult> {
    onSuccessful?: (result: TResult) => undefined | TResult;
    onFailed?: (error: Error) => undefined | null | string;
    silent?: boolean;
}

export type PromiseStatus<TResult> = {
    result: null;
    neverRun: boolean;
    isRunning: boolean;
    isCompleted: false;
    isFailed: false;
    isSuccess: false;
    error: null;
    errorMessage: null;
} | {
    result: null;
    neverRun: false;
    isRunning: false;
    isCompleted: true;
    isFailed: true;
    isSuccess: false;
    error: Error;
    errorMessage: string;
} | {
    result: TResult;
    neverRun: false;
    isRunning: false;
    isCompleted: true;
    isFailed: false;
    isSuccess: true;
    error: null;
    errorMessage: null;
};

type PromiseAction = (...args: unknown[]) => Promise<unknown>;
type PromiseResult<F> = F extends (...args: unknown[]) => Promise<infer R> ? R : F extends Promise<infer R> ? R : never;

export const usePromise = <F extends PromiseAction, R extends PromiseResult<F>>(factory: F, config?: IConfig<R>): [PromiseStatus<R>, (...args: Parameters<F>) => void] => {
    const [currentStatus, setCurrentStatus] = React.useState<PromiseStatus<R>>({ result: null, neverRun: true, isRunning: false, isCompleted: false, isFailed: false, isSuccess: false, error: null, errorMessage: null });
    const runningPromise = React.useRef<Promise<unknown>>();
    const loggingContext = useLoggingContext();

    return [currentStatus, (...args: Parameters<F>): void => {
        // Set up a new status object
        setCurrentStatus({
            result: null,
            neverRun: false,
            isRunning: true,
            isCompleted: false,
            isFailed: false,
            isSuccess: false,
            error: null,
            errorMessage: null,
        });

        // Start the new promise
        const promise = factory(...args);
        runningPromise.current = promise;

        promise.then((result: R) => {
            if (runningPromise.current !== promise) {
                // This is no longer the latest promise, so do nothing.
                return;
            }

            const mutatedResult = config?.onSuccessful?.(result);
            if (mutatedResult !== undefined) {
                result = mutatedResult;
            }
            setCurrentStatus({
                result,
                neverRun: false,
                isRunning: false,
                isCompleted: true,
                isFailed: false,
                isSuccess: true,
                error: null,
                errorMessage: null,
            });
        }).catch((error: Error) => {
            if (runningPromise.current !== promise) {
                // This is no longer the latest promise, so do nothing.
                return;
            }

            // Compose the error message
            let errorMessage = config?.onFailed?.(error);
            if (errorMessage == null) {
                const axiosError = error as AxiosError<{ message: string }>;
                if (axiosError.isAxiosError && axiosError.response && typeof axiosError.response.data === "object") {
                    errorMessage = axiosError.response.data.message;
                }
            }
            if (errorMessage == null) {
                errorMessage = error.message;
            }

            if (config?.silent !== false) {
                loggingContext.writeError(errorMessage);
            }

            setCurrentStatus({
                result: null,
                neverRun: false,
                isRunning: false,
                isCompleted: true,
                isFailed: true,
                isSuccess: false,
                error,
                errorMessage,
            });
        });
    }];
};
