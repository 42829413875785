import config from "@/config";
import { StorageAccountDto } from "@/services/dataTransferObjects";
import { useApiQuery, useApiService, UseQueryOptions } from "./core";

export const storageAccountsApi = {
    useReadAll(options?: UseQueryOptions<StorageAccountDto[]>) {
        const api = useApiService();
        return useApiQuery(["storageaccounts", "list"], () => (
            api.get<StorageAccountDto[]>(`${config.athenaUrl}/api/storageaccounts`)
        ), options);
    },
};
