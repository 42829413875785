import Axios, { AxiosError, AxiosProgressEvent, AxiosRequestConfig } from "axios";
import queryString from "query-string";
import { ErrorTranslator } from "./ErrorTranslator";
import { IApiServiceError } from "./IApiServiceError";

export interface ApiOptions {
    /** The signal to cancel the request */
    signal?: AbortSignal;
    /** Callback for any progress made in the upload */
    progressCallback?: (bytesTransfered: number) => void;
    /** Set to False if an anonymous request is allowed. By default this value is True. */
    requireAccessToken?: boolean;
}

export class ApiService {
    constructor(private readonly accessToken: Promise<string | null>) {
    }

    /**
     * Performs a GET request on the base url with a specified query string.
     * @param url The url of the resource.
     * @param content The content, which is converted to a query string. This argument is optional.
     * @param options The additional options for the request.
     */
    public get<T>(url: string, content?: object | null, options?: ApiOptions): Promise<T> {
        if (content != null) {
            url += "?" + queryString.stringify(content);
        }

        return handleRequest(this.accessToken, (accessToken) => Axios.get<T>(url, composeAxiosConfig(accessToken, options)).then((response) => response.data));
    }

    /**
     * Performs a POST request on the base url with a specified content.
     * @param url The url of the resource.
     * @param content The content which is sent in the request. This argument is optional.
     * @param options The additional options for the request.
     */
    public post<T>(url: string, content: object | null, options?: ApiOptions): Promise<T> {
        return handleRequest(this.accessToken, (accessToken) => Axios.post<T>(url, content, composeAxiosConfig(accessToken, options)).then((response) => response.data));
    }

    /**
     * Performs a PUT request on the base url with a specified content.
     * @param url The url of the resource.
     * @param identity The identity of the item to remove. The identity is placed at the end of the base url.
     * @param content The content which is sent in the request.
     * @param options The additional options for the request.
     */
    public put<T>(url: string, identity: string | number | null, content: object, options?: ApiOptions): Promise<T> {
        return handleRequest(this.accessToken, (accessToken) => Axios.put<T>(appendIdentityToUrl(url, identity), content, composeAxiosConfig(accessToken, options)).then((response) => response.data));
    }

    /**
     * Performs a DELETE request on the base url with the specified identity in the URL.
     * @param url The url of the resource.
     * @param identity The identity of the item to remove. The identity is placed at the end of the base url. This argument is optional.
     * @param options The additional options for the request.
     */
    public delete<T>(url: string, identity?: string | number | null, options?: ApiOptions): Promise<T> {
        return handleRequest(this.accessToken, (accessToken) => Axios.delete<T>(appendIdentityToUrl(url, identity), composeAxiosConfig(accessToken, options)).then((response) => response.data));
    }
}

const handleRequest = <TData>(tokenPromise: Promise<string | null>, action: (token: string | null) => Promise<TData>, abortSignal?: AbortSignal): Promise<TData> => (
    new Promise<TData>((resolve, reject) => (
        tokenPromise.then((accessToken) => (
            action(accessToken).then(resolve)
        )).catch((error: AxiosError) => {
            if (abortSignal?.aborted === true) {
                // Call the callback, indicating that the request has been canceled
                reject({
                    message: error.message,
                    isCanceled: true,
                    statusCode: error.response && error.response.status || 0,
                    errorType: "other",
                } as IApiServiceError);
            } else if (error.response && error.response.data instanceof Object) {
                const data = error.response.data as IApiServiceError;
                reject({
                    message: data.message,
                    errorCode: data.errorCode,
                    isCanceled: false,
                    statusCode: error.response.status || 0,
                    errorType: ErrorTranslator.translate(data.errorCode ?? data.statusCode),
                } as IApiServiceError);
            } else {
                reject({
                    message: error.message,
                    isCanceled: false,
                    statusCode: error.response && error.response.status || 0,
                    errorType: ErrorTranslator.translate(error.response && error.response.status || 0),
                } as IApiServiceError);
            }
        })
    ))
);

/**
 * Translate ApiOptions to settings for Axios and add authorization.
 * @param options The custom option.
 * @param config The default Axios configuration.
 */
const composeAxiosConfig = (token: string | null, options?: ApiOptions, config?: AxiosRequestConfig): AxiosRequestConfig => {
    if (config == null) {
        config = {};
    }
    if (config.headers == null) {
        config.headers = {};
    }

    // Token is required to perform the action.
    if (token == null) {
        if (options?.requireAccessToken !== false) {
            throw Error("Cannot perform the request without token.");
        }
    } else {
        // Add the authorization token
        const authorization = "authorization";
        config.headers[authorization] = token;
    }

    if (options != null) {
        // Add cancelation token
        config.signal = options.signal;

        // Add the progress callback
        if (options.progressCallback != null) {
            config.onUploadProgress = (event: AxiosProgressEvent) => {
                if (options.progressCallback != null) {
                    options.progressCallback(event.loaded);
                }
            };
        }
    }

    return config;
};

/**
 * Append the identity to the base URL as part of the path.
 * @param identity The identity that needs to be appended. If not specified, the base URL is returned.
 */
const appendIdentityToUrl = (url: string, identity: string | number | null | undefined): string => {
    if (identity == null) {
        return url;
    }
    if (url.substr(url.length - 1) !== "/") {
        url += "/";
    }
    return url + identity;
};
