import * as React from "react";

interface IProps {
    code?: string | null;
    codeAlt?: string | null;
}

export const CountryCode: React.FC<IProps> = (props) => (
    <>{(props.code != null ? props.code.toLocaleUpperCase() + (props.codeAlt != null ? " (" + props.codeAlt.toLocaleUpperCase() + ")" : "") : "-")}</>
);
