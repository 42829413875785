import React from "react";
import constate from "constate";
import { usePromise } from "@/utilities";
import { KnownScopes } from "./entities";
import jwtReader from "@/auth/jwtReader";
import { useAuth0Context } from "@/auth/react-auth0-spa";
import { Loader } from "../components/common";
import { Message } from "semantic-ui-react";

interface IProps {
    audience: string;
    scope: string;
    children?: React.ReactNode;
}

const createScopes = (scopes: string[]): KnownScopes => (
    {
        portals: {
            list: scopes.includes("list:portals"),
            read: scopes.includes("read:portals"),
            create: scopes.includes("create:portals"),
            update: scopes.includes("update:portals"),
            delete: scopes.includes("delete:portals"),
        },
        countries: {
            list: scopes.includes("list:countries"),
            read: scopes.includes("read:countries"),
            create: scopes.includes("create:countries"),
            update: scopes.includes("update:countries"),
            delete: scopes.includes("delete:countries"),
        },
        storageAccounts: {
            list: scopes.includes("list:storageaccounts"),
        },
        run: {
            broadcast: scopes.includes("run:BroadcastByNotification"),
        },
    }
);

const useAuth0ServiceClient = (props: IProps) => {
    const [scopes, setScopes] = React.useState<KnownScopes>(createScopes([]));

    const auth0Context = useAuth0Context();

    const [getTokenStatus, getToken] = usePromise(() => auth0Context.getTokenSilently({
        authorizationParams: {
            audience: props.audience,
            scope: props.scope,
        },
    }).catch(() => auth0Context.getTokenWithPopup({
        authorizationParams: {
            audience: props.audience,
            scope: props.scope,
        },
    })));

    const isAuthenticated = React.useMemo(() => (
        !(Object.values(scopes.countries).every((scope: boolean) => !scope) &&
            Object.values(scopes.portals).every((scope: boolean) => !scope) &&
            Object.values(scopes.run).every((scope: boolean) => !scope))
    ), [scopes]);

    React.useEffect(() => {
        getToken();
    }, [props.audience, props.scope]);

    React.useEffect(() => {
        if (getTokenStatus.isSuccess && getTokenStatus.result != null) {
            const newScopes = jwtReader.extractScopes(getTokenStatus.result);
            setScopes(createScopes(newScopes));
        }
    }, [getTokenStatus.result]);

    return {
        isLoading: getTokenStatus.isRunning || getTokenStatus.neverRun,
        isAuthenticated,
        scopes,
        getToken: () => (
            auth0Context.getTokenSilently({ authorizationParams: { audience: props.audience, scope: props.scope } })
        ),
    };
};

const [Auth0ServiceClient, useAuth0ServiceClientContext] = constate(useAuth0ServiceClient);

interface IComponentProps {
    children?: React.ReactNode;
}

const Component: React.FC<IComponentProps> = (props) => {
    const context = useAuth0ServiceClientContext();
    if (context.isLoading) {
        return (<Loader display="inline">Authenticating...</Loader>);
    }
    if (context.isAuthenticated) {
        return (<>{props.children}</>);
    }
    return (<Message negative={true}>Sorry, you don&apos;t have access to this place.</Message>);
};

const Page: React.FC<IProps> = (props) => (
    <Auth0ServiceClient {...props}>
        <Component>{props.children}</Component>
    </Auth0ServiceClient>
);

export { Page as Auth0ServiceClient, useAuth0ServiceClientContext };
