import config from "@/config";
import { argumentUtility } from "../utilities";
import { useApiMutation, useApiService } from "./core";
import { BroadcastDto } from "./dataTransferObjects";

export const jobsApi = {
    useRunBroadcast() {
        const api = useApiService();

        return useApiMutation((data: BroadcastDto) => (
            api.post(`${config.jobsUrl}/api/jobs`,
                {
                    name: "CreateBroadcast_20200604",
                    delayUntil: argumentUtility.optional(data.scheduledDate),
                    parameters: {
                        message: data.message,
                        url: data.url,
                        reason: data.reason,
                    },
                })
        ));
    },
};
