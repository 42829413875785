import { useAuth0ServiceClientContext } from "@/auth";
import { ApiService } from "./ApiService";

export const useApiService = () => {
    const { isAuthenticated, getToken } = useAuth0ServiceClientContext();

    if (!isAuthenticated) {
        throw Error("Cannot use the API when not authenticated.");
    }

    return new ApiService(getToken().then((token) => (token != null ? `Bearer ${token}` : null)));
};
