import * as React from "react";
import { range } from "lodash";
import classNames from "classnames";
import { Pagination, Table } from "semantic-ui-react";
import { fomanticUtility } from "@/utilities";
import * as Utilities from "./Utilities";
import { ITableColumn, SortDirection } from "./ContentTableEntities";

import styles from "./css/SimpleContentTable.scss";
import { LoadingPlaceholder } from "../common/LoadingPlaceholder";

interface IProps<TRow extends object> {
    columns: ITableColumn<TRow>[];
    rows: TRow[];

    noRowsMessage?: string;

    sortedColumnIndex?: number | null;
    sortingDirection?: SortDirection | null;
    fixedHeight?: boolean;
    overflow?: boolean;

    isLoading?: boolean;
    pageNumber: number;
    pageSize: number;
    totalRows: number;

    onPageChange?: (pageNumber: number) => void;
    onHeaderClicked?: (column: ITableColumn<TRow>) => void;
    onRowClicked?: (row: TRow) => void;
    onRowDoubleClicked?: (row: TRow) => void;
}

// The signature of the method below equals to: React.FC<IProps<T>>
export const SimpleContentTable = <TRow extends object>(props: IProps<TRow> & { children?: React.ReactNode }): JSX.Element => {
    const visibleColumns = React.useMemo(() => props.columns.map((layout, index) => ({ layout, index })).filter((c) => c.layout.isVisible !== false), [props.columns]);
    const [rowHeight, setRowHeight] = React.useState<number | null>(null);
    const componentRef = React.useRef<HTMLDivElement | null>(null);

    const rowStyle = React.useMemo(() => (
        { height: rowHeight + "px" }
    ), [rowHeight]);

    const bodyStyle = React.useMemo(() => (
        { height: props.pageSize < props.rows.length ? (props.pageSize * (rowHeight ?? 1)) + ((rowHeight ?? 1) / 2) + "px" : null }
    ), [props.pageSize, rowHeight, props.rows.length]);

    React.useLayoutEffect(() => {
        if (componentRef.current != null) {
            let max = 0;
            // Get the element that is the largest.
            componentRef.current.querySelectorAll("table tbody tr").forEach((element) => {
                max = Math.max(max, element.clientHeight);
            });
            setRowHeight(max);
        }
    }, [props.rows, props.fixedHeight]);

    const onHeaderClicked = (column: ITableColumn<TRow>) => column.field != null && column.header != null && props.onHeaderClicked?.(column);
    const onRowClicked = (row: TRow) => props.onRowClicked?.(row);
    const onRowDoubleClicked = (row: TRow) => props.onRowDoubleClicked?.(row);
    const onPageClick = (pageNo: number) => props.onPageChange?.(pageNo);

    const onCellRender = (row: TRow, column: ITableColumn<TRow>): React.ReactNode => {
        let value: unknown = null;

        // Get the value from the row.
        if (column.field != null) {
            value = Utilities.pluckRecurse(row, column.field);
        }

        // Choose how to render the item.
        if (column.render != null) {
            value = column.render(row, value);
        }
        return value as React.ReactNode;
    };

    const getSorting = (columnIndex: number) => {
        if (props.sortedColumnIndex !== columnIndex || props.sortingDirection == null) {
            return "ascending";
        }
        return props.sortingDirection === "desc" ? "sorted descending" : "sorted ascending";
    };

    const paginationEnabled = props.onPageChange != null;

    return (
        <div className={styles.container} ref={componentRef}>
            <div className={classNames({ [styles.overflow]: props.overflow })}>
                <Table compact="very" unstackable={true} sortable={true} attached="top" selectable={props.onRowClicked != null}>
                    <Table.Header className={classNames({ [styles.noPaginationHeader]: !paginationEnabled && props.overflow })} >
                        <Table.Row>
                            {visibleColumns.map((column) => (
                                <Table.HeaderCell key={column.index} onClick={() => onHeaderClicked(column.layout)}
                                    disabled={column.layout.field == null || column.layout.header == null}
                                    className={classNames(getSorting(column.index), fomanticUtility.convertSemanticSizeToClass(column.layout.size))}
                                >
                                    {column.layout.header}
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body className={classNames({ [styles.noPaginationBody]: !paginationEnabled && props.overflow })} style={bodyStyle}>
                        {props.isLoading === true ? (
                            <Table.Row className={classNames({ [styles.noPaginationRow]: !paginationEnabled && props.overflow })} style={rowStyle}>
                                <Table.Cell colSpan={visibleColumns.length}><LoadingPlaceholder /></Table.Cell>
                            </Table.Row>
                        ) : props.rows.length === 0 ? (
                            <Table.Row className={classNames({ [styles.noPaginationRow]: !paginationEnabled && props.overflow })} style={rowStyle}>
                                <Table.Cell colSpan={visibleColumns.length}><i>{props.noRowsMessage ?? "No items"}</i></Table.Cell>
                            </Table.Row>
                        ) : (props.rows.slice(0, paginationEnabled ? props.pageSize : props.rows.length).map((row, rowIndex) => (
                            <Table.Row key={rowIndex} onClick={() => onRowClicked(row)} onDoubleClick={() => onRowDoubleClicked(row)}
                                className={classNames({ clickable: props.onRowClicked != null }, { [styles.noPaginationRow]: !paginationEnabled && props.overflow })} style={rowStyle}
                            >
                                {visibleColumns.map((column, cellIndex) => (
                                    <Table.Cell key={cellIndex} className={fomanticUtility.convertSemanticSizeToClass(column.layout.size)}>
                                        {onCellRender(row, column.layout)}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        )))}
                        {range(0, props.fixedHeight === true ? props.pageSize - props.rows.length : 0).map((rowIndex) => (
                            <Table.Row key={props.pageSize + rowIndex} className={styles.filler} style={rowStyle}>
                                <Table.Cell colSpan={visibleColumns.length}>&nbsp;</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            {paginationEnabled && (
                <Pagination
                    className="bottom attached"
                    activePage={props.pageNumber}
                    totalPages={Math.max(Math.ceil(props.totalRows / props.pageSize), 1)}
                    onPageChange={(e, p) => onPageClick(Number(p.activePage))}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={2}
                    size="mini"
                />
            )}
        </div>
    );
};

export default SimpleContentTable;
