import React from "react";
import { Button, DropdownItemProps, Form, Grid, Header, Icon, List } from "semantic-ui-react";
import { useAuth0ServiceClientContext } from "@/auth";
import { BottomButtonBar, Checkbox, InputError, Loader, LoggingPlaceholder, StatusLine, TimeLabel } from "@/components/common";
import { useRouter } from "@/hooks";
import { useLogging, withLoggingBoundary } from "@/contexts";
import { portalsApi, storageAccountsApi } from "@/services";
import { PortalDto } from "@/services/dataTransferObjects";
import { DeletePortal } from "./DeletePortal";
import { useParams } from "react-router";

const EditPortal: React.FC = () => {
    const [portal, setPortal] = React.useState<Optional<PortalDto, "guid" | "createdUtc" | "imageUrl" | "storageAccountGuid" | "blobEncryptionCertificateUrl">>({ displayName: "", tenantName: "", url: "", isHidden: false, isSuspended: false, isJobsEnabled: true, hasBlobEncryptionCertificateUrl: false });
    const [pendingDeleteForPortal, setPendingDeleteForPortal] = React.useState<string | null>(null);

    const { scopes } = useAuth0ServiceClientContext();
    const router = useRouter();
    const { portalGuid } = useParams();
    const readPortal = portalsApi.useReadPortal({ id: portalGuid ?? "" }, {
        enabled: portalGuid != null,
        onSuccess: (result) => setPortal(result),
    });

    const readAllStorageAccounts = storageAccountsApi.useReadAll();
    const upsertPortal = portalsApi.useUpsertPortal();
    useLogging([readPortal.error, upsertPortal.error, readAllStorageAccounts.error]);

    const onBackClicked = () => router.portals.list();
    const onSaveClicked = () => upsertPortal.mutate({ portal });

    React.useEffect(() => {
        if (upsertPortal.isSuccess) {
            onBackClicked();
        }
    }, [upsertPortal.status]);

    const autoFillFields = () => {
        if (portal.displayName.length <= 0) {
            return;
        }

        const update = { ...portal };
        const trimmedName = portal.displayName.toLocaleLowerCase().replace(/\W/g, "");
        if (portal.tenantName.length <= 0) {
            update.tenantName = trimmedName.substring(0, 29) + Math.min(999, Math.round(Math.random() * 1000)).toString().padStart(3, "0");
        }
        if (portal.url.length <= 0) {
            update.url = "https://" + trimmedName.substring(0, 40) + ".keeyns.com";
        }
        setPortal(update);
    };

    const storageAcountsOptions = React.useMemo((): DropdownItemProps[] => {
        if (!readAllStorageAccounts.isSuccess) {
            return [];
        }

        return readAllStorageAccounts.data.map((storageAccount) => ({
            text: storageAccount.displayName,
            value: storageAccount.guid,
        }));
    }, [readAllStorageAccounts.data]);

    const isLoading = (portalGuid != null && readPortal.isLoading) || upsertPortal.isLoading || readAllStorageAccounts.isLoading;
    const allFieldsFilled = portal.displayName && portal.tenantName && portal.url;
    return (
        <>
            <Header>{portalGuid != null ? "Edit existing portal" : "Create a new operational portal"}</Header>
            <LoggingPlaceholder />
            <Loader display="placeholder" loading={(portalGuid != null && readPortal.isLoading)}>
                <Grid divided={true}>
                    <Grid.Column width={10}>
                        <Form>
                            <Form.Group>
                                <Form.Input width={10} label="Display name" value={portal.displayName} required={true} maxLength={50} onChange={(a, b) => setPortal({ ...portal, displayName: b.value })} onBlur={autoFillFields} />
                                <Form.Input width={6} label="Tenant name" value={portal.tenantName} required={portalGuid == null} disabled={portalGuid != null} maxLength={32} onChange={(a, b) => setPortal({ ...portal, tenantName: b.value })} />
                            </Form.Group>
                            <Form.Input required={true} label="Public url" value={portal.url} maxLength={140} onChange={(a, b) => setPortal({ ...portal, url: b.value })} />
                            <Form.Input label="Image url (32 x 32 pixels, 72 dpi)" maxLength={255} value={portal.imageUrl ?? ""} onChange={(a, b) => setPortal({ ...portal, imageUrl: b.value.length > 0 ? b.value : null })} />
                            <Form.Dropdown label="Storage account" options={storageAcountsOptions} selection={true} clearable={true} value={portal.storageAccountGuid ?? undefined} disabled={readPortal.data?.storageAccountGuid != null} onChange={(a, b) => setPortal({ ...portal, storageAccountGuid: b.value as string })} />
                            {portal.storageAccountGuid != null && readPortal.data?.storageAccountGuid == null && (<InputError errorState="warning">Be sure to set the correct value, as you can set this only once.</InputError>)}
                            <Form.Input label="Blob encryption certificate" maxLength={255} value={portal.blobEncryptionCertificateUrl ?? (readPortal.data?.hasBlobEncryptionCertificateUrl ? "********" : "")} disabled={readPortal.data?.hasBlobEncryptionCertificateUrl === true} onChange={(a, b) => setPortal({ ...portal, blobEncryptionCertificateUrl: b.value.length > 0 ? b.value : null })} />
                            {portal.blobEncryptionCertificateUrl != null && (<InputError errorState="warning">Be sure to set the correct value, as you can set this only once.</InputError>)}
                        </Form>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <List>
                            <List.Item>
                                <Checkbox label="Suspend portal"
                                    negative={true}
                                    checked={portal.isSuspended}
                                    onChange={(checked) => setPortal({ ...portal, isSuspended: checked })}
                                />
                            </List.Item>
                            <List.Item>
                                <Checkbox label="Portal is hidden"
                                    checked={portal.isHidden}
                                    onChange={(checked) => setPortal({ ...portal, isHidden: checked })}
                                />
                            </List.Item>
                            <List.Item>
                                <Checkbox label="Jobs are enabled"
                                    checked={portal.isJobsEnabled}
                                    onChange={(checked) => setPortal({ ...portal, isJobsEnabled: checked })}
                                />
                            </List.Item>
                        </List>
                        {portalGuid != null && (
                            <List>
                                {readPortal.data != null && readPortal.data.storageAccountGuid == null && (
                                    <List.Item>
                                        <StatusLine errorState="warning">
                                            <StatusLine.Label>No storage account configured</StatusLine.Label>
                                        </StatusLine>
                                    </List.Item>
                                )}
                                <List.Item>
                                    {portal.hasBlobEncryptionCertificateUrl
                                        ? (
                                            <StatusLine errorState="success">
                                                <StatusLine.Label>Blob encryption certificate is set</StatusLine.Label>
                                            </StatusLine>
                                        ) : (
                                            <StatusLine errorState="warning">
                                                <StatusLine.Label>Missing blob encryption certificate</StatusLine.Label>
                                                <StatusLine.Description>Deploy the portal to set this certificate.</StatusLine.Description>
                                            </StatusLine>
                                        )
                                    }
                                </List.Item>
                            </List>
                        )}
                    </Grid.Column>
                </Grid>
                <BottomButtonBar>
                    {portal.createdUtc != null && (
                        <BottomButtonBar.Status>
                            Created <TimeLabel date={portal.createdUtc} />
                        </BottomButtonBar.Status>
                    )}
                    {portal.guid != null && scopes.portals.delete && (
                        <>
                            <Button onClick={() => portal.guid != null && setPendingDeleteForPortal(portal.guid)} disabled={isLoading} icon={<Icon name="delete" color="red" />} className="icon" content="Delete..." />
                            <BottomButtonBar.Divider />
                        </>
                    )}
                    <Button onClick={onBackClicked} disabled={isLoading}>Back</Button>
                    {(portal.guid != null && scopes.portals.update || portal.guid == null && scopes.portals.create) && (
                        <Button onClick={onSaveClicked} disabled={isLoading || !allFieldsFilled} loading={isLoading} primary={true}>Save</Button>
                    )}
                </BottomButtonBar>
            </Loader>
            {pendingDeleteForPortal != null && (
                <DeletePortal portalGuid={pendingDeleteForPortal} onCanceled={() => setPendingDeleteForPortal(null)} onCompleted={onBackClicked} />
            )}
        </>
    );
};

const WrappedEditPortal = withLoggingBoundary(EditPortal);
export { WrappedEditPortal as EditPortal };
