import React from "react";
import { AccountDropdown } from "./AccountDropdown";
import { AccountLocked } from "./AccountLocked";

import styles from "./css/pageHeader.scss";

interface IProps {
    isAuthenticated: boolean;
}

export const PageHeader: React.FC<IProps> = (props) => (
    <nav className={styles.component}>
        <div className="top" />
        <div className="center">
            <a href="/" className="logo">
                <img height="60" src={"/images/logo.png"} />
            </a>
            <span className="button">
                {props.isAuthenticated ? (
                    <AccountDropdown />
                ) : (
                    <AccountLocked />
                )}
            </span>
        </div>
        <div className="bottom" />
    </nav>
);
