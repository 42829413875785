import React from "react";
import { Popup } from "@/components/common";
import { useLogging, withLoggingBoundary } from "@/contexts";
import { countriesApi } from "@/services";

interface IProps {
    countryGuid: string;
    onCompleted: () => void;
    onCanceled: () => void;
}

const DeleteCountry: React.FC<IProps> = (props) => {
    const deleteCountry = countriesApi.useDeleteCountry();
    React.useEffect(() => {
        if (deleteCountry.isSuccess) {
            props.onCompleted();
        }
    }, [deleteCountry.status]);
    useLogging([deleteCountry.error]);

    const onDeleteConfirmed = () => {
        deleteCountry.mutate({ guid: props.countryGuid});
    };

    return (
        <Popup header="Delete Country">
            <Popup.Confirm isLoading={deleteCountry.isLoading} onConfirmClicked={onDeleteConfirmed} onCancelClicked={props.onCanceled}>
                Are you sure to delete this country?
            </Popup.Confirm>
        </Popup>
    );
};

const WrappedDeleteCountry = withLoggingBoundary(DeleteCountry);
export { WrappedDeleteCountry as DeleteCountry };
