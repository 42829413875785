import React from "react";
import { Routes, Route } from "react-router";
import { routes as keeynsRoutes } from "@/hooks";
import { Auth0ServiceClient, useAuth0Context } from "@/auth";
import { LoggingPlaceholder } from "@/components/common";
import { Menubar, PageHeader } from "@/components/navigation";
import { ManagePortalsPage } from "./ManagePortalsPage";
import { ManageCountriesPage } from "./ManageCountriesPage";
import { ManageBroadcastsPage } from "./ManageBroadcastsPage";
import { WelcomePage } from "./WelcomePage";
import { ContentPage } from "./ContentPage";

import styles from "./css/layout.scss";
import config from "@/config";

export const Layout: React.FC = () => {
    const { isAuthenticated, user } = useAuth0Context();

    return (
        <>
            <Menubar />
            <PageHeader isAuthenticated={isAuthenticated} />
            <div className={styles.content}>
                <LoggingPlaceholder />
                <ContentPage>
                    {user != null && (
                        <Routes>
                            <Route path="/" element={<WelcomePage /> } />
                            <Route path={keeynsRoutes.portals} element={(
                                <Auth0ServiceClient audience={config.athenaAudience} scope="list:portals read:portals create:portals update:portals delete:portals list:storageaccounts">
                                    <ManagePortalsPage />
                                </Auth0ServiceClient>
                            )}
                            />
                            <Route path={keeynsRoutes.countries} element={(
                                <Auth0ServiceClient audience={config.athenaAudience} scope="list:countries read:countries create:countries update:countries delete:countries">
                                    <ManageCountriesPage />
                                </Auth0ServiceClient>
                            )}
                            />
                            <Route path={keeynsRoutes.broadcasts} element={(
                                <Auth0ServiceClient audience={config.jobsAudience} scope="run:BroadcastByNotification">
                                    <ManageBroadcastsPage />
                                </Auth0ServiceClient>
                            )}
                            />
                        </Routes>
                    )}
                </ContentPage>
            </div>
        </>
    );
};
