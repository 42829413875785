import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthorizationParams } from "@auth0/auth0-spa-js";
import { Auth0Provider } from "@/auth";
import { LoggingProvider } from "@/contexts";
import { IApiServiceError } from "@/services/core";
import config from "@/config";
import { Layout } from "@/pages/Layout";

import "./css/index.g.scss";

const rootElement = document.getElementById("root");
if (rootElement == null) {
    throw Error("Cannot find the application root element");
}

const MaxRetryAttemptsForQueries = 3;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000,
            refetchOnWindowFocus: false,
            retry: (count, error: IApiServiceError) => count < MaxRetryAttemptsForQueries && (error.statusCode < 400 || error.statusCode > 499),
        },
    },
});

const authorizationParams: AuthorizationParams = { redirect_uri: window.location.origin };

const root = createRoot(rootElement);
root.render(
    <LoggingProvider>
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <Auth0Provider
                    domain={config.domain}
                    clientId={config.clientId}
                    authorizationParams={authorizationParams}
                >
                    <Layout />
                </Auth0Provider>
            </QueryClientProvider>
        </BrowserRouter>
    </LoggingProvider>
);
