import { useAuth0ServiceClientContext } from "@/auth";
import { BottomButtonBar, MoreOptions } from "@/components/common";
import { ITableColumn, SortableContentTable } from "@/components/contentTable";
import { useRouter } from "@/hooks";
import { CountryDto } from "@/services/dataTransferObjects";
import { need } from "@/utilities";
import React from "react";
import { Button, Header } from "semantic-ui-react";
import { CountryCode } from "./CountryCode";
import { CountryEUStatus } from "./CountryEUStatus";
import CountryFilter, { ICurrentFilter } from "./CountryFilter";
import { CountryFlag } from "./CountryFlag";
import { CountryNames } from "./CountryNames";
import { DeleteCountry } from "./DeleteCountry";
import { PathRouteProps } from "react-router";

import styles from "./css/CountriesTable.scss";

interface IProps extends PathRouteProps {
    onOpen: (guid: string | null) => void;
    countries: CountryDto[];
}

const CountriesTable: React.FC<IProps> = (props) => {
    const [appliedFilter, setAppliedFilter] = React.useState<ICurrentFilter>({ code2: null, name: null, code3: null });
    const [pendingDeleteForCountryGuid, setPendingDeleteForCountryGuid] = React.useState<string | null>(null);

    const router = useRouter();
    const { scopes } = useAuth0ServiceClientContext();

    const onNewClicked = () => router.countries.new();
    const onEditClicked = (country: CountryDto) => scopes.countries.read && props.onOpen(country.guid);

    const columns = React.useMemo(() => (
        [{
            header: "Country Names", size: 12, defaultSortDirection: "asc", field: "countryNames", render: (country: CountryDto) => (
                <span className={styles.countryNames}><CountryFlag code2={country.code2} /> <CountryNames countryNames={country.countryNames} /></span>
            ),
        },
        {
            header: "Code 2 / Code 3", size: 4, field: "code2", render: (country: CountryDto) => (
                <span><CountryCode code={country.code2} codeAlt={country.code2Alt} /> / <CountryCode code={country.code3} codeAlt={country.code3Alt} /></span>
            ),
        },

        {
            header: "EU", field: (v) => v.europeanUnion[v.europeanUnion.length - 1]?.isMember, defaultSortDirection: "desc", render: (country: CountryDto) => (
                <CountryEUStatus europeanUnion={country.europeanUnion} />
            ),
        },
        {
            render: (country: CountryDto) => (
                <>
                    <Button size="tiny" icon="edit" circular={true} color="orange" onClick={() => onEditClicked(country)} />
                    <MoreOptions size="tiny" direction="left">
                        <MoreOptions.Item icon="delete" iconColor="red" label="Delete..." onClick={() => setPendingDeleteForCountryGuid(country.guid)} />
                    </MoreOptions>
                </>
            ),
        },
        ] as ITableColumn<CountryDto>[]
    ), [props.countries]);

    const countries = React.useMemo(() => (
        props.countries.filter((c) => (
            (appliedFilter.code2 == null || c.code2.toLocaleUpperCase() === appliedFilter.code2.toLocaleUpperCase() || c.code2Alt?.toLocaleUpperCase() === appliedFilter.code2.toLocaleUpperCase())
            && (appliedFilter.code3 == null || c.code3?.toLocaleUpperCase() === appliedFilter.code3.toLocaleUpperCase() || c.code3Alt?.toLocaleUpperCase() === appliedFilter.code3.toLocaleUpperCase())
            && (appliedFilter.name == null || c.countryNames.some((n) => n.toLocaleUpperCase().includes(need(appliedFilter.name).toLocaleUpperCase())))
        ))
    ), [props.countries, appliedFilter]);

    const onFilterChanged = (filter: ICurrentFilter) => {
        if (appliedFilter.code2 !== filter.code2 || appliedFilter.code3 !== filter.code3 || appliedFilter.name !== filter.name) {
            setAppliedFilter(filter);
        }
    };

    return (
        <>
            <Header>Countries Registrations</Header>
            <div className={styles.toolbar}>
                <CountryFilter
                    filter={appliedFilter}
                    onFilterChanged={onFilterChanged}
                />
            </div>
            <SortableContentTable defaultSortedColumnIndex={0} onRowClicked={(c: CountryDto) => onEditClicked(c)} defaultSortingDirection="asc" noRowsMessage="No Countries Available" rows={countries} columns={columns} />
            {scopes.countries.create && (
                <BottomButtonBar>
                    <Button onClick={onNewClicked} primary={true}>New Country</Button>
                </BottomButtonBar>
            )}
            {pendingDeleteForCountryGuid != null && (
                <DeleteCountry countryGuid={pendingDeleteForCountryGuid} onCanceled={() => setPendingDeleteForCountryGuid(null)} onCompleted={() => setPendingDeleteForCountryGuid(null)} />
            )}
        </>
    );
};

export { CountriesTable };
