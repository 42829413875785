import * as React from "react";
import { Image } from "semantic-ui-react";

interface IProps {
    code2: string;
}

export const CountryFlag: React.FC<IProps> = (props) => (
    <Image spaced={true} size="mini" src={`https://keeynscdn.blob.core.windows.net/portal/flags/circle_44/${props.code2}.png`} />
);
