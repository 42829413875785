import * as React from "react";
import SimpleContentTable from "./SimpleContentTable";
import * as Utilities from "./Utilities";
import { ITableColumn, SortDirection } from "./ContentTableEntities";

interface IProps<TRow extends object> {
    columns: ITableColumn<TRow>[];
    rows: TRow[];
    noRowsMessage?: string;
    isLoading?: boolean;
    defaultSortedColumnIndex?: number;
    defaultSortingDirection?: SortDirection;
    fixedHeight?: boolean;
    overflow?: boolean;
    onRowClicked?: (row: TRow) => void;
    onRowDoubleClicked?: (row: TRow) => void;
}

// The signature of the method below equals to: React.FC<IProps<T>>
export const SortableContentTable = <TRow extends object>(props: IProps<TRow> & { children?: React.ReactNode }): JSX.Element => {
    const PAGE_SIZE = 10;

    const [pageNumber, setPageNumber] = React.useState(1);
    const [sortedColumnIndex, setSortedColumnIndex] = React.useState<number | null>(props.defaultSortedColumnIndex ?? null);
    const [sortingDirection, setSortingDirection] = React.useState<SortDirection>(props.defaultSortingDirection || "asc");

    // Go back to page one if the rows change.
    React.useEffect(() => {
        // Change the page number only when is loading is enabled and thus the whole list will refresh
        if (props.isLoading) {
            setPageNumber(1);
        // If data changes, check if the current page is still allowed
        } else if (props.rows.length <= (pageNumber - 1) * PAGE_SIZE) {
            // Go to the last page
            setPageNumber(Math.max(1, Math.ceil(props.rows.length / PAGE_SIZE)));
        }
    }, [props.rows]);

    // Default sorting changes, so apply it here.
    React.useEffect(() => {
        if (props.defaultSortedColumnIndex != null) {
            setSortedColumnIndex(props.defaultSortedColumnIndex);
        }
    }, [props.defaultSortedColumnIndex]);
    React.useEffect(() => {
        if (props.defaultSortingDirection != null) {
            setSortingDirection(props.defaultSortingDirection);
        }
    }, [props.defaultSortingDirection]);

    // Get the rows, sorted if required.
    const rows = React.useMemo(() => {
        let sortedRows = props.rows;
        if (sortedColumnIndex != null) {
            sortedRows = Utilities.sortRows(sortedRows, props.columns[sortedColumnIndex], sortingDirection);
        }
        return sortedRows;
    }, [props.rows, sortedColumnIndex, sortingDirection]);

    const pagedRows = React.useMemo(() => {
        const from = (pageNumber - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        return rows.slice(from, to);
    }, [rows, pageNumber]);

    const onHeaderClicked = (column: ITableColumn<TRow>) => {
        const columnIndex = props.columns.findIndex((c) => c === column);
        if (columnIndex < 0) {
            throw Error("Cannot find the columns to sort on.");
        }

        // Change direction
        let newSortingDirection: SortDirection;
        if (sortedColumnIndex === columnIndex) {
            newSortingDirection = sortingDirection === "asc" ? "desc" : "asc";
        } else {
            setSortedColumnIndex(columnIndex);
            newSortingDirection = column.defaultSortDirection || "asc";
        }
        setSortingDirection(newSortingDirection);
    };
    const onPageClick = (pageNo: number) => {
        setPageNumber(pageNo);
    };

    return (
        <SimpleContentTable
            noRowsMessage={props.noRowsMessage}
            fixedHeight={props.fixedHeight}
            overflow={props.overflow}
            onHeaderClicked={onHeaderClicked}
            onRowClicked={props.onRowClicked}
            onRowDoubleClicked={props.onRowDoubleClicked}
            onPageChange={onPageClick}
            pageNumber={pageNumber}
            pageSize={PAGE_SIZE}
            rows={pagedRows}
            columns={props.columns}
            totalRows={props.rows.length}
            isLoading={props.isLoading}
            sortedColumnIndex={sortedColumnIndex}
            sortingDirection={sortingDirection}
        />
    );
};
export default SortableContentTable;
