import React from "react";
import { Header } from "semantic-ui-react";

import styles from "./css/welcomePage.scss";

export const WelcomePage: React.FC = () => (
    <div>
        <Header>Keeyns management</Header>
        <div className={styles.consoleBlock}>
            <code>
                <span className="yellow">Welcome to Keeyns Management portal</span><br />
                ] idqd<br />
                ] idkfa<br /><br />
                ] <span className="white">_</span>
            </code>
        </div>
    </div>
);
