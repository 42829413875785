import { EditPortal, PortalsTable } from "@/components/managePortals";
import { useRouter } from "@/hooks";
import { PortalDto } from "@/services/dataTransferObjects";
import { Routes, Route } from "react-router";
import React from "react";

export const ManagePortalsPage: React.FC = () => {
    const router = useRouter();
    const onOpen = (portal: PortalDto) => {
        router.portals.edit(portal.guid);
    };

    return (
        <Routes>
            <Route path="/" element={<PortalsTable onOpen={onOpen} />} />
            <Route path="new" element={<EditPortal />} />
            <Route path="edit/:portalGuid" element={<EditPortal />} />
        </Routes>
    );
};
