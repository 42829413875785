import { Loader } from "@/components/common";
import { CountriesTable, EditCountry } from "@/components/manageCountries";
import { useRouter } from "@/hooks";
import { countriesApi } from "@/services";
import { Routes, Route } from "react-router";
import React from "react";

export const ManageCountriesPage: React.FC = () => {
    const allCountries = countriesApi.useReadAll({ onSuccess: (data) => data.sort((a, b) => b.countryNames[0].localeCompare(a.countryNames[0])) });
    const router = useRouter();

    const onOpen = (guid: string) => {
        router.countries.edit(guid);
    };

    return (
        <>
            <Loader display="inline" loading={allCountries.isLoading }/>
            {allCountries.isSuccess && (
                <Routes>
                    <Route path="/" element={<CountriesTable onOpen={onOpen} countries={allCountries.data} />} />
                    <Route path="new" element={<EditCountry />} />
                    <Route path="edit/:countryGuid" element={<EditCountry />} />
                </Routes>
            )}
        </>
    );
};
