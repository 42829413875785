// The CONFIG variable is assigned by the script that is injected by the application
declare const SPACONFIG: Config;

interface Config {
    domain: string;
    clientId: string;
    athenaAudience: string;
    athenaUrl: string;
    jobsAudience: string;
    jobsUrl: string;
}

export default SPACONFIG;
