import React from "react";
import { Popup } from "@/components/common";
import { useLogging, withLoggingBoundary } from "@/contexts";
import { portalsApi } from "@/services";

interface IProps {
    portalGuid: string;
    onCompleted: (guid: string) => void;
    onCanceled: () => void;
}

const DeletePortal: React.FC<IProps> = (props) => {
    const deletePortal = portalsApi.useDeletePortal();
    React.useEffect(() => {
        if (deletePortal.isSuccess) {
            props.onCompleted(props.portalGuid);
        }
    }, [deletePortal.status]);
    useLogging([deletePortal.error]);

    const onDeleteConfirmed = () => {
        deletePortal.mutate({ id: props.portalGuid });
    };

    return (
        <Popup header="Confirm delete">
            <Popup.Confirm isLoading={deletePortal.isLoading} onConfirmClicked={onDeleteConfirmed} onCancelClicked={props.onCanceled}>
                Are you sure to delete this portal registration?
            </Popup.Confirm>
        </Popup>
    );
};

const WrappedDeletePortal = withLoggingBoundary(DeletePortal);
export { WrappedDeletePortal as DeletePortal };
