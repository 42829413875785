import React from "react";
import { Dimmer, Icon, Table, Segment, Loader, Transition } from "semantic-ui-react";

import styles from "./css/LoadingPlaceholder.scss";

interface IProps {
    loading?: boolean;
    fadeOut?: boolean;
    display?: "inline" | "tableRow" | "placeholder" | "fullscreen";
    loadingContent?: React.ReactNode;
    children?: React.ReactNode;
}

export const LoadingPlaceholder: React.FC<IProps> = (props) => {
    if (props.loading === false && props.fadeOut !== true) {
        return null;
    }

    let loader: JSX.Element;
    switch (props.display) {
        case "fullscreen":
            return props.loading !== false ? (
                <Dimmer page={true} active={true}>
                    <Loader active={true} size="large" content={props.loadingContent ?? "Loading..."} />
                </Dimmer>
            ) : null;
        case "placeholder":
            loader = (<Segment basic={true} loading={true} className={styles.placeholder}>{props.loadingContent}</Segment>);
            break;
        case "tableRow":
            loader = (<Table.Row><Table.Cell colSpan={99}><LoadingPlaceholder loadingContent={props.loadingContent}>{props.children}</LoadingPlaceholder></Table.Cell></Table.Row>);
            break;
        case "inline":
        default:
            loader = (<div className={styles.inline}><Icon name="circle notch" loading={true} />{props.loadingContent ?? "Loading..."}</div>);
            break;
    }

    return (
        <Transition visible={props.loading !== false} animation="slide down" duration={props.loading === false && props.fadeOut === true ? 500 : 0}>
            <span>
                {loader}
            </span>
        </Transition>
    );
};
