import { withLoggingBoundary } from "@/contexts";
import React from "react";
import { Header } from "semantic-ui-react";
import { CreateBroadcast } from "./CreateBroadcast";

const BroadcastsTable: React.FC = () => (
    <>
        <Header>Broadcast a notification</Header>
        <CreateBroadcast />
    </>
);

const WrappedBroadcastsTable = withLoggingBoundary(BroadcastsTable);
export { WrappedBroadcastsTable as BroadcastsTable };
