export const argumentUtility = {
    /**
     * Returns the content or undefined if the content is falsy
     * @param content
     */
    optional<T>(content: T): T | undefined {
        if (content == null) {
            return undefined;
        }
        return content;
    },

    /**
     * Returns the content or null if the content is falsy
     * @param content
     */
    required<T>(content: T): T | null {
        if (content == null) {
            return null;
        }
        return content;
    },

    /**
     * Returns the content
     * @param content
     */
    optionalIfUndefined<T>(content: T): T {
        return content;
    },
};
