import { useAuth0ServiceClientContext } from "@/auth";
import { BottomButtonBar, LoggingPlaceholder } from "@/components/common";
import { useLogging, withLoggingBoundary } from "@/contexts";
import { useRouter } from "@/hooks";
import { countriesApi, timeZonesApi } from "@/services";
import { CountryDto, EuropeanUnionDto } from "@/services/dataTransferObjects";
import _ from "lodash";
import React from "react";
import { Button, Form, Grid, Header, Icon, List } from "semantic-ui-react";
import CountryEUStatusInput from "./CountryEUStatusInput";
import { DeleteCountry } from "./DeleteCountry";
import { useParams } from "react-router";

import styles from "./css/EditCountry.scss";

const EditCountry: React.FC = () => {
    const [newCountry, setNewCountry] = React.useState<Optional<CountryDto, "guid" | "code2Alt" | "code3" | "code3Alt">>({ countryNames: [], code2: "", europeanUnion: [], timeZone: "" });
    const [pendingDeleteForCountry, setPendingDeleteForCountry] = React.useState<string | null>(null);
    const [newCountryName, setNewCountryName] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const { countryGuid } = useParams();

    const selectedCountry = countriesApi.useReadCountry({ guid: countryGuid ?? "" }, { enabled: countryGuid != null });

    const useReadAllTimeZones = timeZonesApi.useReadAll();

    const allTimeZonesOptions = React.useMemo(() => {
        if (useReadAllTimeZones.isSuccess) {
            return useReadAllTimeZones.data.map((tz) => ({
                key: tz.name,
                text: tz.name,
                value: tz.name,
            }));
        }
        return [];
    }, [useReadAllTimeZones.data]);

    const { scopes } = useAuth0ServiceClientContext();
    const router = useRouter();

    const upsertCountry = countriesApi.useUpsertCountry();
    useLogging([upsertCountry.error, errorMessage]);

    const onBackClicked = () => router.countries.list();
    const onSaveClicked = () => {
        upsertCountry.reset();

        if (evaluateNewCountry(selectedCountry.data ?? {}, newCountry)) {
            upsertCountry.mutate({ newCountry });
        }
    };

    React.useEffect(() => {
        if (upsertCountry.isSuccess) {
            onBackClicked();
        }
    }, [upsertCountry.status]);

    React.useEffect(() => {
        if (selectedCountry.isSuccess) {
            setNewCountry(selectedCountry.data);
        }
    }, [selectedCountry.data]);

    const onSaveEUStatus = ((eu: EuropeanUnionDto[]) => {
        setNewCountry({ ...newCountry, europeanUnion: eu });
    });

    const onAddNewCountryName = () => {
        if (!newCountry.countryNames.some((name) => name === newCountryName)) {
            setNewCountry({ ...newCountry, countryNames: newCountry.countryNames.concat(newCountryName) });
            setNewCountryName("");
        }
    };

    const setDefaultCountryName = (index: number) => {
        const orderedCountryNames = [...newCountry.countryNames];
        orderedCountryNames.unshift(orderedCountryNames.splice(index, 1)[0]);
        setNewCountry({ ...newCountry, countryNames: orderedCountryNames });
    };

    const onChangeOptionalCode = (key: keyof CountryDto, value: string) => {
        if (value.length <= 0) {
            setNewCountry({ ...newCountry, [key]: null });
        } else {
            setNewCountry({ ...newCountry, [key]: value.toLocaleLowerCase().replace(/[^a-z]/g, "") });
        }
    };

    const hasValidCountryCodes = (codeLength: 2 | 3, code?: string | null, codeAlt?: string | null): boolean => {

        if (codeAlt != null) {
            if (code == null) {
                setErrorMessage(`Code ${codeLength} Alt can't be set before Code ${codeLength}`);
                onChangeOptionalCode(("code" + codeLength + "Alt") as keyof CountryDto, "");
                return false;
            }
            if (codeAlt.length !== codeLength) {
                setErrorMessage(`Code ${codeLength} Alt is incomplete`);
                return false;
            }
            if (codeAlt === code) {
                setErrorMessage(`Code ${codeLength} and Code ${codeLength} Alt can't be the same`);
                return false;
            }

        }

        if (code != null) {
            if (code.length !== codeLength) {
                setErrorMessage(`Code ${codeLength} is incomplete`);
                return false;
            }
        }
        return true;
    };

    const isDifferent = (currentCountry: Partial<CountryDto>, updatedCountry: Optional<CountryDto, "guid" | "code2Alt" | "code3" | "code3Alt">): boolean => (
        (!(_.isEqual(currentCountry.countryNames, updatedCountry.countryNames) &&
            _.isEqual(currentCountry.europeanUnion, updatedCountry.europeanUnion) &&
            currentCountry.code2 === updatedCountry.code2 &&
            currentCountry.code2Alt === updatedCountry.code2Alt &&
            currentCountry.code3 === updatedCountry.code3 &&
            currentCountry.code3Alt === updatedCountry.code3Alt &&
            currentCountry.timeZone === updatedCountry.timeZone))
    );

    const evaluateNewCountry = (currentCountry: Partial<CountryDto>, updatedCountry: Optional<CountryDto, "guid" | "code2Alt" | "code3" | "code3Alt">): boolean => {
        setErrorMessage(null);

        if (updatedCountry.countryNames.length === 0 || !updatedCountry.code2 || !updatedCountry.timeZone) {
            setErrorMessage("The following fields are required: Name, Code 2, Time Zone");
            return false;
        }

        if (!hasValidCountryCodes(2, updatedCountry.code2, updatedCountry.code2Alt)) {
            return false;
        }
        if (!hasValidCountryCodes(3, updatedCountry.code3, updatedCountry.code3Alt)) {
            return false;
        }

        if (!isDifferent(currentCountry, updatedCountry)) {
            onBackClicked();
            return false;
        }

        return true;
    };

    return (
        <>
            <Header>{countryGuid != null ? "Edit existing country" : "Create a new country"}</Header>
            <LoggingPlaceholder />
            <Grid divided={true}>
                <Grid.Column width={8}>
                    <List>
                        <List.Item>
                            <List.Header>Name(s)</List.Header>
                        </List.Item>
                        {newCountry.countryNames.map((name, i) => (
                            <List.Item key={i}>
                                <div className={styles.countryNames}>
                                    <div className={styles.countryName}>
                                        {name}
                                    </div>
                                    <div className={styles.countryButtons}>
                                        {i !== 0 && <Icon floated="right" onClick={() => setDefaultCountryName(i)} color="blue" name="pin" />}
                                        <Icon floated="right" onClick={() => setNewCountry({ ...newCountry, countryNames: newCountry.countryNames.filter((n) => n !== name) })} color="red" name="delete" />
                                    </div>
                                </div>
                            </List.Item>
                        ))}
                    </List>
                    <Form onSubmit={() => onAddNewCountryName()}>
                        <Form.Field>
                            <Form.Input value={newCountryName} onChange={(k, n) => setNewCountryName(n.value)} error={newCountry.countryNames.length === 0 && {
                                content: "A country must have a name",
                                pointing: "above",
                            }}
                            >
                                <input />
                                <Button floated="right" compact={true} onClick={() => onAddNewCountryName()} color="blue" disabled={!newCountryName || newCountry.countryNames.some((name) => name === newCountryName)} icon="plus" />
                            </Form.Input>
                        </Form.Field>
                    </Form>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form>
                        <Form.Group>
                            <Form.Field width={4}>
                                <Form.Input required={true} readOnly={newCountry.guid != null} error={newCountry.code2.length <= 0 && {
                                    content: "Code 2 is a required field",
                                    pointing: "above",
                                }} label="Code 2" value={newCountry.code2} maxLength={2} onChange={(k, v) => setNewCountry({ ...newCountry, code2: v.value.toLocaleLowerCase().replace(/[^a-z]/g, "") })}
                                />
                            </Form.Field>
                            <Form.Field width={4}>
                                <Form.Input label="Code 2 Alt" value={newCountry.code2Alt ?? ""} maxLength={2} onChange={(k, v) => onChangeOptionalCode("code2Alt", v.value)} />
                            </Form.Field>
                            <Form.Field width={8}>
                                <Form.Select error={newCountry.timeZone.length <= 0 && {
                                    content: "Time Zone name is a required field",
                                    pointing: "above",
                                }} search={true} options={allTimeZonesOptions} label="Time Zone Name" value={newCountry.timeZone} maxLength={50} onChange={(k, v) => setNewCountry({ ...newCountry, timeZone: v.value as string })}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group>
                            <Form.Field width={4}>
                                <Form.Input label="Code 3" value={newCountry.code3 ?? ""} maxLength={3} onChange={(k, v) => onChangeOptionalCode("code3", v.value)} />
                            </Form.Field>
                            <Form.Field width={4}>
                                <Form.Input label="Code 3 Alt" disabled={newCountry.code3?.length !== 3} value={newCountry.code3Alt ?? ""} maxLength={3} onChange={(k, v) => onChangeOptionalCode("code3Alt", v.value)} />
                            </Form.Field>
                            <Form.Field width={8}>
                                <label>Current EU Status</label>
                                <CountryEUStatusInput country={newCountry} onSave={onSaveEUStatus} />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid>
            <BottomButtonBar>
                {countryGuid != null && scopes.portals.delete && (
                    <Button onClick={() => countryGuid && setPendingDeleteForCountry(countryGuid)} icon={<Icon name="delete" color="red" />} className="icon" content="Delete..." />
                )}
                <BottomButtonBar.Divider />
                <Button onClick={onBackClicked} disabled={upsertCountry.isLoading}>Back</Button>
                <Button onClick={onSaveClicked} disabled={newCountry === selectedCountry.data || upsertCountry.isLoading} loading={upsertCountry.isLoading} primary={true}>Save</Button>
            </BottomButtonBar>
            {pendingDeleteForCountry != null && (
                <DeleteCountry countryGuid={pendingDeleteForCountry} onCanceled={() => setPendingDeleteForCountry(null)} onCompleted={onBackClicked} />
            )}
        </>
    );
};

const WrappedEditCountry = withLoggingBoundary(EditCountry);
export { WrappedEditCountry as EditCountry };
