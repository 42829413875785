import { BottomButtonBar, LoggingPlaceholder } from "@/components/common";
import { useLogging, withLoggingBoundary } from "@/contexts";
import React from "react";
import { Button, Checkbox, DropdownProps, Form, Header, Modal, Select } from "semantic-ui-react";
import { jobsApi } from "@/services/";
import { BroadcastDto } from "@/services/dataTransferObjects/";

const CreateBroadcast: React.FC = () => {
    const [broadcast, setBroadcast] = React.useState<BroadcastDto>({ message: "", url: "", reason: "", scheduledDate: null });
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const broadcastNotificationApi = jobsApi.useRunBroadcast();
    useLogging([errorMessage, broadcastNotificationApi.error]);

    const broadcastTypes = [
        { text: "New release", url: "https://keeyns.com/category/release-notes/", message: "Keeyns has been updated to the latest version.", reason: "release:new" },
    ];

    const onSelectReleaseType = (releaseType: DropdownProps) => {
        const broadcastType = broadcastTypes.find((bt) => bt.text === releaseType.value as string);
        if (broadcastType) {
            setBroadcast({ ...broadcast, message: broadcastType.message, url: broadcastType.url, reason: broadcastType.reason });
        }
    };

    const onSendBroadcast = () => {
        broadcastNotificationApi.reset();
        setErrorMessage(null);

        if (isValidBroadcast(broadcast)) {
            broadcastNotificationApi.mutate(broadcast);
        }
    };

    const onToggle = (state: boolean) => {
        broadcastNotificationApi.reset();
        setErrorMessage(null);
        setBroadcast({ message: "", url: "", reason: "", scheduledDate: null });
        setIsModalOpen(state);
    };

    React.useEffect(() => {
        if (broadcastNotificationApi.isSuccess) {
            onToggle(false);
        }
    }, [broadcastNotificationApi.isSuccess]);

    const isValidBroadcast = (broadcastDto: BroadcastDto): boolean => {
        if (!isValidUrl(broadcastDto.url)) {
            setErrorMessage("The URL is invalid");
            return false;
        }

        if (!isValidText(broadcastDto.message)) {
            setErrorMessage("Text should be between 1 and 100 characters");
            return false;
        }

        if (broadcastDto.scheduledDate != null && !isValidDate(broadcastDto.scheduledDate)) {
            setErrorMessage("The scheduled date must be greater than the current time");
            return false;
        }

        return true;
    };

    const onTextChange = (newText: string) => {
        if (newText.length <= 100) {
            setBroadcast({ ...broadcast, message: newText });
        }
    };

    const onChangeDate = (date: string) => {
        const newDate = new Date(date);
        newDate.setSeconds(0);
        setBroadcast({ ...broadcast, scheduledDate: newDate.toISOString() });
    };

    const onSetIsScheduledImmediately = () => {
        if (broadcast.scheduledDate != null) {
            setBroadcast({ ...broadcast, scheduledDate: null });
        } else {
            onChangeDate(new Date().toISOString());
        }
    };

    return (
        <>
            <Button onClick={() => onToggle(true)} primary={true}>Create new broadcast...</Button>
            <Modal open={isModalOpen}>
                <Modal.Content>
                    <Header>Broadcast a notification</Header>
                    <LoggingPlaceholder />
                    <div>
                        <Form>
                            <Form.Group>
                                <Form.Field width={16}>
                                    <Form.Input type="text" required={true} placeholder="URL" onChange={(e, v) => setBroadcast({ ...broadcast, url: v.value })} value={broadcast.url} label="Url">
                                        <input />
                                        <Select options={ broadcastTypes.map((bt, i) => ({ key: i, text: bt.text, value: bt.text }))} onChange={(e, v) => onSelectReleaseType(v)} placeholder="Select a release type" />
                                    </Form.Input>
                                </Form.Field>
                            </Form.Group>
                            <Form.Group>
                                <Form.Field width={4}>
                                    <label>Send Immediately</label>
                                    <Checkbox onChange={onSetIsScheduledImmediately} checked={broadcast.scheduledDate == null} />
                                </Form.Field>
                                <Form.Field width={12}>
                                    <Form.Input label="Scheduled Date" disabled={broadcast.scheduledDate == null} type="datetime-local" onChange={(d) => onChangeDate(d.target.value)} min={new Date().toISOString().substring(0, 16)}/>
                                </Form.Field>
                            </Form.Group>
                            <Form.Input onChange={(e, v) => onTextChange(v.value)} label="Broadcast Text" type="text" value={broadcast.message} required={true} />
                        </Form>
                        <BottomButtonBar>
                            <Button onClick={() => onToggle(false)}>Cancel</Button>
                            <Button loading={broadcastNotificationApi.isLoading} disabled={broadcastNotificationApi.isLoading} onClick={onSendBroadcast} primary={true}>Send</Button>
                        </BottomButtonBar>
                    </div>
                </Modal.Content>
            </Modal>
        </>
    );
};

const isValidUrl = (url: string): boolean => {
    // Valid Keeyns domain URL
    //
    // Https://www.keeyns.com/release/          (any number of subdirectories allowed)
    // Https://bean.keeyns.com/something/new    (max 1 sub domain allowed)
    // Http://keeyns.com                        (http secure, subdomain, subdirectory optional)
    const urlRegex = /^http(s)?:\/\/([\w]+\.)?(keeyns\.com)(\/[^\s]*)?$/gi;

    return urlRegex.test(url);
};

const isValidText = (text: string): boolean => (
    (text.length >= 1 && text.length <= 100)
);

const isValidDate = (date: string): boolean => (
    new Date(date) > new Date()
);

const WrappedCreateBroadcast = withLoggingBoundary(CreateBroadcast);
export { WrappedCreateBroadcast as CreateBroadcast };
