import React from "react";
import { Table, Header, Button } from "semantic-ui-react";
import { useAuth0ServiceClientContext } from "@/auth";
import { useLogging, withLoggingBoundary } from "@/contexts";
import { useRouter } from "@/hooks";
import { portalsApi } from "@/services";
import { PortalDto } from "@/services/dataTransferObjects";
import { BottomButtonBar, Loader, LoggingPlaceholder, MoreOptions, LabelTag } from "@/components/common";
import { DeletePortal } from "./DeletePortal";
import { PathRouteProps } from "react-router";

interface IProps extends PathRouteProps {
    onOpen: (portal: PortalDto) => void;
}

const PortalsTable: React.FC<IProps> = (props) => {
    const [pendingDeleteForPortal, setPendingDeleteForPortal] = React.useState<string | null>(null);

    const readPortals = portalsApi.useReadPortals({ onSuccess: (data) => data.sort((a, b) => a.displayName.localeCompare(b.displayName)) });
    useLogging([readPortals.error]);

    const { scopes } = useAuth0ServiceClientContext();
    const router = useRouter();

    const onNewClicked = () => router.portals.new();
    const onEditClicked = (portal: PortalDto) => scopes.portals.read && props.onOpen(portal);
    const onDeleteCompleted = () => setPendingDeleteForPortal(null);

    return (
        <>
            <Header>Portal registrations in production</Header>
            <LoggingPlaceholder />
            <Table compact="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Web address</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {readPortals.isLoading && (
                        <Loader display="table-row" />
                    )}
                    {readPortals.isSuccess && (readPortals.data.map((portal) => (
                        <Table.Row key={portal.guid} onDoubleClick={() => onEditClicked(portal)}>
                            <Table.Cell>{portal.displayName}</Table.Cell>
                            <Table.Cell>
                                <a href={portal.url} onClick={(e) => e.stopPropagation()} target="_blank" rel="noopener noreferrer">{portal.url}</a>
                                <LabelTag text="hidden" isVisible={portal.isHidden} />
                                <LabelTag text="prevent jobs" isVisible={!portal.isJobsEnabled} />
                                <LabelTag text="suspended" errorState="warning" isVisible={portal.isSuspended} />
                            </Table.Cell>
                            <Table.Cell collapsing={true}>
                                <Button size="tiny" icon="edit" circular={true} color="orange" onClick={() => onEditClicked(portal)} />
                                <MoreOptions direction="left" size="tiny">
                                    <MoreOptions.Item icon="delete" iconColor="red" label="Delete..." onClick={() => setPendingDeleteForPortal(portal.guid)} />
                                </MoreOptions>
                            </Table.Cell>
                        </Table.Row>
                    )))}
                </Table.Body>
            </Table>
            {scopes.portals.create && (
                <BottomButtonBar>
                    <Button onClick={onNewClicked} primary={true}>Create new registration...</Button>
                </BottomButtonBar>
            )}
            {pendingDeleteForPortal != null && (
                <DeletePortal portalGuid={pendingDeleteForPortal} onCompleted={onDeleteCompleted} onCanceled={() => setPendingDeleteForPortal(null)} />
            )}
        </>
    );
};

const WrappedPortalsTable = withLoggingBoundary(PortalsTable);
export { WrappedPortalsTable as PortalsTable };
