import * as React from "react";
import { Button, Form, Select } from "semantic-ui-react";

type FilterCountryBy = "code2" | "code3" | "name";

export interface ICurrentFilter {
    code2?: string | null;
    code3?: string | null;
    name?: string | null;
}

interface IProps {
    filter: ICurrentFilter;
    onFilterChanged: (filter: ICurrentFilter) => void;
}

export const CountryFilter: React.FC<IProps> = (props) => {
    const [filterBy, setFilterBy] = React.useState<FilterCountryBy | null>(props.filter.code2 != null ? "code2" : props.filter.code3 != null ? "code3" : "name");
    const [filterText, setFilterText] = React.useState(props.filter.code2 ?? props.filter.code3 ?? props.filter.name ?? "");

    const applyTextFilter = () => {
        const newFilter: ICurrentFilter = {};
        switch (filterBy) {
            case "code2": newFilter.code2 = filterText || undefined; break;
            case "code3": newFilter.code3 = filterText || undefined; break;
            case "name": newFilter.name = filterText || undefined; break;
            default: break;
        }
        props.onFilterChanged(newFilter);
    };

    const clearFilter = () => {
        setFilterText("");
        props.onFilterChanged({});
    };

    const onTextChange = (newText: string) => {
        if (filterBy === "code2" || filterBy === "code3") {
            setFilterText(newText.replace(/[^A-Za-z]/g, ""));
        } else {
            setFilterText(newText);
        }
    };

    const changeFilter = (filter: FilterCountryBy) => {
        setFilterBy(filter);
        clearFilter();
    };

    const options = [
        { key: "name", text: "Name", value: "name" },
        { key: "code2", text: "Code 2", value: "code2" },
        { key: "code3", text: "Code 3", value: "code3" },
    ];

    return (
        <div>
            <Form onSubmit={() => applyTextFilter()}>
                <Form.Input type="text" placeholder="Search..." action={true} onChange={(e, v) => onTextChange(v.value)} value={filterText}>
                    <input />
                    <Select fluid={true} options={options} onChange={(e, v) => changeFilter(v.value as FilterCountryBy)} defaultValue={"name"} />
                    <Button type="submit" onClick={() => applyTextFilter()}>Search</Button>
                    <Button basic={true} name="delete" onClick={() => clearFilter()}>Clear Filter</Button>
                </Form.Input>
            </Form>
        </div>
    );
};

export default CountryFilter;
