import config from "@/config";
import { useApiMutation, useApiQuery, useApiService, useQueryClient, UseQueryOptions } from "./core";
import { CountryDto } from "@/services/dataTransferObjects";

interface ReadCountryFilter { guid: string }
interface UpsertCountryData { newCountry: Partial<CountryDto> }
interface DeleteCountryData { guid: string }

export const countriesApi = {
    useReadAll(options?: UseQueryOptions<CountryDto[]>) {
        const api = useApiService();
        return useApiQuery(["countries", "list"], () => (
            api.get<CountryDto[]>(`${config.athenaUrl}/api/countries`)
        ), options);
    },

    useReadCountry(filter: ReadCountryFilter, options?: UseQueryOptions<CountryDto>) {
        const api = useApiService();
        return useApiQuery(["countries", "read", filter.guid], () => (
            api.get<CountryDto>(`${config.athenaUrl}/api/countries/${filter.guid}`)
        ), options);
    },

    useUpsertCountry() {
        const queryClient = useQueryClient();
        const api = useApiService();
        return useApiMutation((data: UpsertCountryData) => (
            data.newCountry.guid == null
                ? api.post<CountryDto>(`${config.athenaUrl}/api/countries`, {
                    countryNames: data.newCountry.countryNames,
                    code2: data.newCountry.code2,
                    code2Alt: data.newCountry.code2Alt,
                    code3: data.newCountry.code3,
                    code3Alt: data.newCountry.code3Alt,
                    europeanUnion: data.newCountry.europeanUnion,
                    timeZone: data.newCountry.timeZone,
                })
                : api.put<CountryDto>(`${config.athenaUrl}/api/countries`, data.newCountry.guid, {
                    countryNames: data.newCountry.countryNames,
                    code2: data.newCountry.code2,
                    code2Alt: data.newCountry.code2Alt,
                    code3: data.newCountry.code3,
                    code3Alt: data.newCountry.code3Alt,
                    europeanUnion: data.newCountry.europeanUnion,
                    timeZone: data.newCountry.timeZone,
                })
        ), {
            onSuccess: (result) => {
                queryClient.invalidateQueries(["countries", "list"]);
                queryClient.invalidateQueries(["countries", "item", result.guid]);
            },
        });
    },

    useDeleteCountry() {
        const queryClient = useQueryClient();
        const api = useApiService();

        return useApiMutation((data: DeleteCountryData) => (
            api.delete<undefined>(`${config.athenaUrl}/api/countries`, data.guid)
        ), {
            onSuccess: (result, data) => {
                queryClient.invalidateQueries(["countries", "list"]);
                queryClient.invalidateQueries(["countries", "item", data.guid]);
            },
        });
    },
};
