import moment from "moment";

export const dateUtility = {
    // Provides utilities for dates from the database and js type Date

    dateToString(date: Date) {
        return date.toISOString().slice(0, 10);
    },

    dateDBToString(date: string) {
        return date.slice(0, 10);
    },

    addDaysToDateDB(date: string, days: number) {
        if (date) {
            const newDate = this.toDate(this.dateDBToString(date));
            newDate.setDate(newDate.getDate() + days);
            return this.dateToString(newDate);
        }
        return null;
    },

    toDate(date: string) {
        if (date) {
            return new Date(date.valueOf());
        }
        return null;
    },

    removeTimeZoneOffsetDate(date: string) {
        if (date) {
            date = moment(date).format("YYYY-MM-DDT00:00") + "Z";
            return date;
        }
        return null;
    },
};
