import * as React from "react";
import { Icon, Label } from "semantic-ui-react";

import styles from "./css/LabelTag.scss";

interface IProps {
    text: string;
    isVisible?: boolean;
    errorState?: "success" | "warning" | "error";
}

export const LabelTag: React.FC<IProps> = (props) => {
    if (props.isVisible === false) {
        return null;
    }
    return (
        <span className={styles.component}>
            <Label className={props.errorState} size="mini">
                {props.errorState === "success" ? (
                    <Icon name="check circle outline" />
                ) : props.errorState === "warning" ? (
                    <Icon name="exclamation triangle" />
                ) : props.errorState === "error" && (
                    <Icon name="times circle" />
                )}
                {props.text}
            </Label>
        </span>
    );
};

