import * as React from "react";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";

import styles from "./css/InputError.scss";

interface IProps extends React.PropsWithChildren {
    errorState: "info" | "success" | "warning" | "error";
}

export const InputError: React.FC<IProps> = (props) => (
    <div className={classNames(styles.inputError, props.errorState)}>
        <div>
            <Icon className={classNames(styles.icon, {
                ["info circle"]: props.errorState === "info",
                ["check circle"]: props.errorState === "success",
                ["exclamation mark triangle"]: props.errorState === "warning",
                ["exclamation mark circle"]: props.errorState === "error"
            })}
            />
        </div>
        <div>
            {props.children}
        </div>
    </div>
);
