import * as React from "react";
import { Divider, Icon } from "semantic-ui-react";

import styles from "./css/BottomButtonBar.scss";

const ButtonDivider: React.FC = () => (
    <Icon />
);

const ButtonStatus: React.FC<React.PropsWithChildren> = (props) => (
    <span className={styles.status}>{props.children}</span>
);

interface IProps {
    children?: React.ReactNode;
}

export const BottomButtonBar: React.FC<IProps> & { Divider: typeof ButtonDivider, Status: typeof ButtonStatus } = (props) => (
    <div className={styles.component}>
        <Divider />
        {props.children}
    </div>
);
BottomButtonBar.Divider = ButtonDivider;
BottomButtonBar.Status = ButtonStatus;
