import { CountryDto, EuropeanUnionDto } from "@/services/dataTransferObjects";
import * as React from "react";
import { Button, Checkbox, Form, Icon, Input, Modal, Table } from "semantic-ui-react";
import { dateUtility, need } from "@/utilities";

interface IProps {
    country: Optional<CountryDto, "guid" | "code2Alt" | "code3" | "code3Alt">;
    onSave: (eu: EuropeanUnionDto[] | null) => void;
}

export const CountryEUStatusInput: React.FC<IProps> = (props) => {
    const [isTableModalVisible, setIsTableModalVisible] = React.useState(false);
    const [newEUStatus, setNewEUStatus] = React.useState<EuropeanUnionDto[]>([]);
    const [currentEUStatusDate, setCurrentEUStatusDate] = React.useState<EuropeanUnionDto | null>(null);

    const onChangesConfirmed = () => {
        props.onSave(newEUStatus);
        setCurrentEUStatusDate(null);
        setIsTableModalVisible(false);
    };
    const onChangesCancelled = () => {
        setNewEUStatus(props.country.europeanUnion);
        setCurrentEUStatusDate(null);
        setIsTableModalVisible(false);
    };

    const onDeleteClicked = ((eu: EuropeanUnionDto) => {
        setNewEUStatus(newEUStatus.filter((all) => all !== eu));
    });

    const onAddClicked = (() => {
        setNewEUStatus(newEUStatus.concat(need(currentEUStatusDate)));
        setCurrentEUStatusDate(null);
    });

    React.useEffect(() => {
        setNewEUStatus(props.country.europeanUnion);
    }, [props.country.europeanUnion]);

    const latestEUStatus = React.useMemo(() => (
        newEUStatus[newEUStatus.length - 1] as (EuropeanUnionDto | undefined) ?? null
    ), [newEUStatus]);

    return (
        <>
            <Input readOnly={true}
                onClick={() => setIsTableModalVisible(true)}
                value={latestEUStatus != null ? (latestEUStatus.isMember ? "" : "Not ") + "Since " + (dateUtility.dateDBToString(latestEUStatus.effectiveDate)) : "Has never been in the EU"}
            />
            <Modal open={isTableModalVisible}>
                <Modal.Header>Country EU Status Configuration</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group>
                            <Form.Field width={2}>
                                <label>In EU?</label>
                                <Checkbox disabled={true} checked={!(latestEUStatus?.isMember ?? false)} />
                            </Form.Field>
                            <Form.Field width={6}>
                                <label>Date Status Changed</label>
                                <input pattern="\d{4}-\d{2}-\d{2}" type="date"
                                    onChange={(d) => setCurrentEUStatusDate({ isMember: !(latestEUStatus?.isMember ?? false), effectiveDate: need(dateUtility.removeTimeZoneOffsetDate(d.target.value)) })}
                                    min={latestEUStatus != null ? dateUtility.addDaysToDateDB(latestEUStatus.effectiveDate, 1) : undefined}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Add</label>
                                <Button icon="plus" primary={true} onClick={() => onAddClicked()} disabled={currentEUStatusDate?.effectiveDate == null } />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                                <Table.HeaderCell width={14}>Date</Table.HeaderCell>
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {newEUStatus.slice().reverse().map((eu) => (
                                <Table.Row key={eu.effectiveDate}>
                                    <Table.Cell width={2}>{eu.isMember ? <Icon name="check circle" color="green" /> : <Icon name="close" color="red" />}</Table.Cell>
                                    <Table.Cell width={14}>{dateUtility.dateDBToString(eu.effectiveDate)}</Table.Cell>
                                    <Table.Cell collapsing={true}>
                                        <Button size="tiny" icon="delete" circular={true} color="red" onClick={() => onDeleteClicked(eu)} disabled={latestEUStatus?.effectiveDate !== eu.effectiveDate} />
                                    </Table.Cell>
                                </Table.Row>),
                            )}
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <Button content="Cancel" onClick={onChangesCancelled} />
                    <Button content="OK" primary={true} onClick={onChangesConfirmed} />
                </Modal.Actions>
            </Modal>
        </>
    );
};
export default CountryEUStatusInput;
