import * as React from "react";
import classNames from "classnames";
import { ButtonGroup, Button, Icon } from "semantic-ui-react";

import styles from "./css/Checkbox.scss";

interface IProps {
    checked: boolean;
    secondary?: boolean;
    negative?: boolean;
    label?: string | null;
    options?: "yesno" | "onoff";
    disabled?: boolean;
    onChange: (checked: boolean) => void;
}

export const Checkbox: React.FC<IProps> = (props) => {
    let trueLabel: string | undefined;
    let falseLabel: string | undefined;
    switch (props.options) {
        case "onoff":
            trueLabel = "On";
            falseLabel = "Off";
            break;
        case "yesno":
            trueLabel = "Yes";
            falseLabel = "No";
            break;
        default:
            trueLabel = undefined;
            falseLabel = undefined;
            break;
    }

    return (
        <div className={styles.component}>
            <ButtonGroup size="mini" basic={true} onClick={() => !props.disabled && props.onChange(!props.checked)}>
                <Button disabled={props.disabled} className={classNames({ selected: props.checked, secondary: props.secondary, negative: props.negative })}
                    icon={<Icon name="checkmark" />}
                    content={props.checked ? trueLabel : undefined}
                />
                <Button disabled={props.disabled} className={classNames({ selected: !props.checked })}
                    icon="times"
                    content={!props.checked ? falseLabel : undefined}
                />
            </ButtonGroup>
            {props.label != null && (
                <label className={classNames({ disabled: props.disabled })}>{props.label}</label>
            )}
        </div>
    );
};
