import { toNumber, toString } from "lodash";
import { ITableColumn, SortDirection } from "./ContentTableEntities";

export type ValueSelector<TRow extends object> = (row: TRow) => unknown;

export const pluckRecurse = <T extends object>(item: T, field: string | ValueSelector<T>): unknown => {
    if (typeof field === "function") {
        return field(item);
    }

    let namedItem = item as ObjectIndexer | null;
    field.split(".").forEach((v) => namedItem = (namedItem?.[v] ?? null) as ObjectIndexer);
    return namedItem;
};

export const sortRows = <T extends object>(rows: T[], sortColumn: ITableColumn<T>, sortDirection: SortDirection): T[] => {
    // If the column has no field to sort on, skip it.
    if (sortColumn.field == null) {
        return rows;
    }

    const sortedRows = rows.slice().sort((a, b) => {
        const aValue = pluckRecurse(a, sortColumn.field as string);
        const bValue = pluckRecurse(b, sortColumn.field as string);
        let result = 0;

        // Check if any value is null
        if (aValue == null && bValue == null) {
            result = 0;
        } else if (aValue == null && bValue != null) {
            result = -1;
        } else if (aValue != null && bValue == null) {
            result = 1;
        } else {
            switch (sortColumn.dataType) {
                case "number":
                    result = toNumber(aValue) - toNumber(bValue);
                    break;
                default: // Or date or string
                    result = toString(aValue).localeCompare(toString(bValue));
                    break;
            }
        }
        return result;
    });
    return sortDirection === "asc" ? sortedRows : sortedRows.reverse();
};
