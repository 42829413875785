import { SemanticWIDTHS } from "semantic-ui-react";

export const fomanticUtility = {
    /**
     * Convert the Semantic UI widths to class names.
     * @param size
     */
    convertSemanticSizeToClass(size: SemanticWIDTHS | null | undefined): string {
        if (size == null) {
            return "collapsing";
        }

        switch (size.toString()) {
            case "1": return "one wide";
            case "2": return "two wide";
            case "3": return "three wide";
            case "4": return "four wide";
            case "5": return "five wide";
            case "6": return "six wide";
            case "7": return "seven wide";
            case "8": return "eight wide";
            case "9": return "nine wide";
            case "10": return "ten wide";
            case "11": return "eleven wide";
            case "12": return "twelve wide";
            case "13": return "thirteen wide";
            case "14": return "fourteen wide";
            case "15": return "fifteen wide";
            case "16": return "sixteen wide";
            default: return size + " wide";
        }
    },
};
