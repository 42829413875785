import React from "react";
import { Sidebar, Menu, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router";

import styles from "./css/menubar.scss";

// See for the template: https://github.com/frontendfunn/semantic-ui-admin-dashboard-template

export const Menubar: React.FC = () => {
    const navigate = useNavigate();
    const open = (route: string) => navigate(route);
    return (
        <Sidebar as={Menu} inverted={true} vertical={true} visible={true} className={styles.component}>
            <Menu.Item as="a" onClick={() => open("/portals")}>
                <div>
                    <Icon name="globe" /> Manage portals
                </div>
            </Menu.Item>
            <Menu.Item as="a" onClick={() => open("/countries")}>
                <div>
                    <Icon name="flag" /> Manage countries
                </div>
            </Menu.Item>
            <Menu.Item as="a" onClick={() => open("/broadcasts")}>
                <div>
                    <Icon name="bullhorn" /> Broadcast a message
                </div>
            </Menu.Item>
            <Menu.Item>
                <Menu.Header>Jobs</Menu.Header>
                <div className="menu">
                    <Menu.Item as="a" disabled={true}>
                        <div><Icon name="bolt" /> Job overview</div>
                    </Menu.Item>
                    <Menu.Item as="a" disabled={true}>
                        <div><Icon name="tachometer alternate" /> Analyze failed jobs</div>
                    </Menu.Item>
                </div>
            </Menu.Item>
            <Menu.Item>
                <Menu.Header>Tax Engine</Menu.Header>
                <div className="menu">
                    <Menu.Item as="a" disabled={true}>
                        <div><Icon name="cogs" /> Tax Engine overview</div>
                    </Menu.Item>
                    <Menu.Item as="a" disabled={true}>
                        <div><Icon name="tachometer alternate" /> Analyze failed runs</div>
                    </Menu.Item>
                </div>
            </Menu.Item>
        </Sidebar>
    );
};
