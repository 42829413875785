import { useMemo } from "react";
import { MutationFunction, QueryFunction, QueryKey, QueryObserverBaseResult, QueryObserverLoadingErrorResult, QueryObserverLoadingResult, QueryObserverRefetchErrorResult, QueryObserverResult, QueryObserverSuccessResult, QueryOptions, useMutation, UseMutationOptions, UseMutationResult, useQueries, useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { IApiServiceError } from "./IApiServiceError";

type UseApiQueryType = <TQueryFnData = unknown, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(queryKey: TQueryKey, queryFn: QueryFunction<TQueryFnData, TQueryKey>, options?: Omit<UseQueryOptions<TQueryFnData, IApiServiceError, TData, TQueryKey>, "queryKey" | "queryFn">) => UseQueryResult<TData, IApiServiceError>;
type UseApiMutationType = <TData = unknown, TVariables = void, TContext = unknown > (mutationFn: MutationFunction<TData, TVariables>, options?: Omit<UseMutationOptions<TData, IApiServiceError, TVariables, TContext>, "mutationFn">) => UseMutationResult<TData, IApiServiceError, TVariables, TContext>;

export const useApiQuery: UseApiQueryType = (queryKey, queryFn, options) => useQuery(queryKey, queryFn, options);
export const useApiMutation: UseApiMutationType = (mutationFn, options) => useMutation(mutationFn, options);

export { useQueryClient, type UseQueryOptions, type UseMutationOptions } from "@tanstack/react-query";

export const useApiQueries = <T extends object>(queries: QueryOptions<T, IApiServiceError>[]) => {
    const queryCollection = useQueries({ queries });
    return useMemo(() => {
        const result = {
            loadingQueries: [] as QueryObserverLoadingResult<T>[],
            fetchingQueries: [] as QueryObserverBaseResult<T>[],
            successQueries: [] as QueryObserverSuccessResult<T>[],
            errorQueries: [] as (QueryObserverLoadingErrorResult<T> | QueryObserverRefetchErrorResult<T>)[],
            all: [] as QueryObserverResult<T>[],
            dataUpdatedAt: 0,
            errorUpdatedAt: 0,
            isLoading: false,
            isFetching: false,
            isSuccess: true,
            isError: false,
            errors: [] as IApiServiceError[],
        };

        queryCollection.forEach((query) => {
            // Merge basic data
            result.dataUpdatedAt = Math.max(query.dataUpdatedAt, result.dataUpdatedAt);
            result.errorUpdatedAt = Math.max(query.errorUpdatedAt, result.errorUpdatedAt);
            result.isLoading ||= query.isLoading;
            result.isFetching ||= query.isFetching;
            result.isSuccess &&= query.isSuccess;
            result.isError ||= query.isError;

            // Add the query to the 'all' collection
            result.all.push(query);

            // Add the query to the specific collection.
            if (query.isSuccess) {
                result.successQueries.push(query);
            } else if (query.isError) {
                result.errorQueries.push(query);
                result.errors.push(query.error as IApiServiceError);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            } else if (query.isLoading) {
                result.loadingQueries.push(query);
            }
            if (query.isFetching) {
                result.fetchingQueries.push(query);
            }
        });

        return result;
    }, [queries]);
};
