import * as React from "react";
import { Label } from "semantic-ui-react";

interface IProps {
    countryNames: string[];
}

export const CountryNames: React.FC<IProps> = (props) => (
    <>
        {props.countryNames[0]}
        {props.countryNames.length > 1 && (
            <Label> +{props.countryNames.length - 1} more...</Label>
        )}
    </>
);
