const KeeynsErrorMapping = {
    networkError: 0,
    validationError: 400,
    unauthorizedAccess: 401,
    accessDenied: 403,
    notFound: 404,
    conflict: 409,
    featureDisabled: 903,
    keeynsVersionMismatch: 10071,
};

export type KeeynsError = keyof typeof KeeynsErrorMapping | "other";

export class ErrorTranslator {
    /**
     * Utility to translate an error code to a KeeynsError.
     */
    public static translate(errorCode: unknown): KeeynsError {
        if (errorCode != null) {
            if (typeof errorCode === "string") {
                errorCode = Number(errorCode);
            }
            if (typeof errorCode === "number" && !Number.isNaN(errorCode)) {
                const mapping = KeeynsErrorMapping as ObjectIndexer;
                for (const k in mapping) {
                    if (mapping[k] === (errorCode as number)) {
                        return k as KeeynsError;
                    }
                }
            }
        }
        return "other";
    }

    public static backToCode(errorCode: KeeynsError): number {
        if (errorCode === "other") {
            return 500;
        } else if (errorCode === "networkError") {
            return 0;
        }
        return KeeynsErrorMapping[errorCode];
    }
}
