import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { LoggingPlaceholder } from "./LoggingPlaceholder";

interface IProps {
    header: React.ReactNode;
    isVisible?: boolean;
    children?: React.ReactNode;
}

interface IConfirmProps {
    onConfirmClicked: () => void;
    onCancelClicked: () => void;
    isLoading?: boolean;
    children?: React.ReactNode;
}

const Confirm: React.FC<IConfirmProps> = (props) => (
    <>
        <Modal.Content>
            <LoggingPlaceholder />
            {props.children}
        </Modal.Content>
        <Modal.Actions>
            <Button onClick={props.onCancelClicked} disabled={props.isLoading === true}>Cancel</Button>
            <Button onClick={props.onConfirmClicked} primary={true} disabled={props.isLoading === true} loading={props.isLoading === true}>OK</Button>
        </Modal.Actions>
    </>
);

export const Popup: React.FC<IProps> & { Confirm: typeof Confirm } = (props) => (
    <Modal open={props.isVisible !== false}>
        <Modal.Header>{props.header}</Modal.Header>
        {props.children}
    </Modal>
);
Popup.Confirm = Confirm;
