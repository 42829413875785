import React from "react";
import { Dimmer, Icon, Table, Segment, Loader as SemanticUILoader } from "semantic-ui-react";

interface IProps {
    loading?: boolean;
    children?: React.ReactNode;
    display?: "inline" | "table-row" | "placeholder" | "fullscreen";
}

export const Loader: React.FC<IProps> = (props) => {
    if (props.loading === false) {
        return props.children != null ? (<>{props.children}</>) : null;
    }

    switch (props.display) {
        case "fullscreen":
            return (
                <Dimmer page={true} active={true}>
                    <SemanticUILoader active={true} size="large" content={props.children ?? "Loading..."} />
                </Dimmer>
            );
        case "placeholder":
            return (<Segment basic={true} loading={true} style={{ padding: 0 }}>{props.children}</Segment>);
        case "table-row":
            return (<Table.Row><Table.Cell colSpan={99}><Loader>{props.children}</Loader></Table.Cell></Table.Row>);
        case "inline":
        default:
            return (<><Icon name="circle notch" loading={true} />{props.children ?? "Loading..."}</>);
    }
};
