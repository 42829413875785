import { useNavigate } from "react-router";
import { useMemo } from "react";

export const routes = {
    portals: "/portals/*",
    countries: "/countries/*",
    broadcasts: "/broadcasts/*",
};

export const useRouter = () => {
    const navigate = useNavigate();
    return useMemo(() => ({
        portals: {
            list: () => navigate("/portals"),
            new: () => navigate("/portals/new"),
            edit: (id: string) => navigate(`/portals/edit/${id}`),
        },
        countries: {
            list: () => navigate("/countries"),
            new: () => navigate("/countries/new"),
            edit: (guid: string) => navigate(`/countries/edit/${guid}`),
        },
        broadcasts: {
            list: () => navigate("/broadcasts"),
        },
    }), [navigate]);
};
