import config from "@/config";
import { PortalDto } from "@/services/dataTransferObjects";
import { useApiMutation, useApiQuery, useApiService, useQueryClient, UseQueryOptions } from "./core";

interface ReadPortalFilter { id: string }
interface UpsertPortalData { portal: Partial<PortalDto> }
interface DeletePortalData { id: string }

export const portalsApi = {
    useReadPortals(options?: UseQueryOptions<PortalDto[]>) {
        const api = useApiService();
        return useApiQuery(["portals", "list"], () => (
            api.get<PortalDto[]>(`${config.athenaUrl}/api/portals`, { _hidden: "include" })
        ), options);
    },

    useReadPortal(filter: ReadPortalFilter, options?: UseQueryOptions<PortalDto>) {
        const api = useApiService();
        return useApiQuery(["portals", "item", filter.id], () => (
            api.get<PortalDto>(`${config.athenaUrl}/api/portals/${filter.id}`)
        ), options);
    },

    useUpsertPortal() {
        const queryClient = useQueryClient();
        const api = useApiService();

        return useApiMutation((data: UpsertPortalData) => (
            data.portal.guid == null
                ? api.post<PortalDto>(`${config.athenaUrl}/api/portals`, {
                    url: data.portal.url,
                    displayName: data.portal.displayName,
                    tenantName: data.portal.tenantName,
                    imageUrl: data.portal.imageUrl,
                    storageAccountGuid: data.portal.storageAccountGuid,
                    blobEncryptionCertificateUrl: data.portal.blobEncryptionCertificateUrl,
                    isSuspended: data.portal.isSuspended,
                    isHidden: data.portal.isHidden,
                    isJobsEnabled: data.portal.isJobsEnabled,
                })
                : api.put<PortalDto>(`${config.athenaUrl}/api/portals`, data.portal.guid, {
                    guid: data.portal.guid,
                    url: data.portal.url,
                    displayName: data.portal.displayName,
                    imageUrl: data.portal.imageUrl,
                    storageAccountGuid: data.portal.storageAccountGuid,
                    blobEncryptionCertificateUrl: data.portal.blobEncryptionCertificateUrl,
                    isSuspended: data.portal.isSuspended,
                    isHidden: data.portal.isHidden,
                    isJobsEnabled: data.portal.isJobsEnabled,
                })
        ), {
            onSuccess: (result) => {
                queryClient.invalidateQueries(["portals", "list"]);
                queryClient.invalidateQueries(["portals", "item", result.guid]);
            },
        });
    },

    useDeletePortal() {
        const queryClient = useQueryClient();
        const api = useApiService();

        return useApiMutation((data: DeletePortalData) => (
            api.delete<undefined>(`${config.athenaUrl}/api/portals`, data.id)
        ), {
            onSuccess: (result, data) => {
                queryClient.invalidateQueries(["portals", "list"]);
                queryClient.invalidateQueries(["portals", "item", data.id]);
            },
        });
    },
};
