import React from "react";
import { Message } from "semantic-ui-react";
import { useLoggingContext } from "@/contexts/LoggingContext";

interface IProps {
    icons?: boolean;
    default?: React.ReactNode;
}

export const LoggingPlaceholder: React.FC<IProps> = (props) => {
    const loggingContext = useLoggingContext();

    if (loggingContext.errors.length === 0 && props.default != null) {
        return (<>{props.default}</>);
    }

    return (
        <>
            {loggingContext.errors.map((error, index) => (
                <Message key={index} error={true} icon={props.icons === true ? "error" : undefined} content={error} visible={true} />
            ))}
        </>
    );
};
