import * as React from "react";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";

import styles from "./css/StatusLine.scss";

interface IProps extends React.PropsWithChildren {
    errorState: "success" | "warning" | "error";
}

const Label: React.FC<{ children: React.ReactNode }> = (props) => (
    <div>{props.children}</div>
);

const Description: React.FC<{ children: React.ReactNode }> = (props) => (
    <div className={styles.description}>{props.children}</div>
);

export const StatusLine: React.FC<IProps> & {
    Label: typeof Label,
    Description: typeof Description,
} = (props) => (
    <div className={classNames(styles.statusLine, props.errorState)}>
        <div><Icon className={classNames(styles.icon, { ["check circle outline"]: props.errorState === "success", ["exclamation mark triangle"]: props.errorState === "warning", ["times circle"]: props.errorState === "error" })} /></div>
        <div>
            {props.children}
        </div>
    </div>
);
StatusLine.Label = Label;
StatusLine.Description = Description;
