import React from "react";
import constate from "constate";
import { Auth0Client, Auth0ClientOptions, createAuth0Client, RedirectLoginOptions, GetTokenSilentlyOptions, GetTokenWithPopupOptions } from "@auth0/auth0-spa-js";
import { usePromise } from "@/utilities";
import { KeeynsUser } from "./entities";
import * as claims from "./claims";
import { useNavigate } from "react-router";

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

interface Auth0User {
    sub: string;
    family_name: string;
    given_name: string;
    email: string;
    picture: string;
    [claims.activeTenantClaimKey]: string[];
    [claims.groupClaimKey]: string[];
}

type Auth0Context = {
    isAuthenticated: false,
    user: null,
    loading: boolean,
    loginWithRedirect(o: RedirectLoginOptions): Promise<void>,
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<undefined | string>,
    getTokenWithPopup(options?: GetTokenWithPopupOptions): Promise<string>,
    logout(): void,
} | {
    isAuthenticated: true,
    user: KeeynsUser,
    loading: false,
    loginWithRedirect(o: RedirectLoginOptions): Promise<void>,
    getTokenSilently(o?: GetTokenSilentlyOptions): Promise<string>,
    getTokenWithPopup(options?: GetTokenWithPopupOptions): Promise<string | undefined>,
    logout(): void,
};

const useAuth0 = ({ ...config }: Auth0ClientOptions): Auth0Context => {
    const navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = React.useState(false);
    const [user, setUser] = React.useState<KeeynsUser | null>(null);
    const [auth0Client, setAuth0] = React.useState<Auth0Client | null>(null);

    const [initAuth0Status, initAuth0] = usePromise(async () => {
        const auth0FromHook = await createAuth0Client(config);
        setAuth0(auth0FromHook);

        if (window.location.search.includes("code=") &&
            window.location.search.includes("state=")) {
            const { appState } = await auth0FromHook.handleRedirectCallback();
            if (appState != null) {
                navigate(appState);
            } else {
                DEFAULT_REDIRECT_CALLBACK();
            }
        }

        const isAuthenticatedByAuth = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(isAuthenticatedByAuth);

        if (isAuthenticatedByAuth) {
            const newUser = await auth0FromHook.getUser<Auth0User>();
            if (newUser == null) {
                throw Error("Missing user");
            }

            setUser({
                id: newUser.sub,
                fullName: newUser.given_name + " " + newUser.family_name,
                pictureUrl: newUser.picture,
                email: newUser.email,
                activeTenants: newUser["https://claims.keeyns.com/active_tenants"],
            });
        }
    });
    React.useEffect(initAuth0, []);

    if (isAuthenticated && user != null && auth0Client != null && !(initAuth0Status.isRunning || initAuth0Status.neverRun)) {
        return {
            isAuthenticated,
            user,
            loading: false,
            loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
            getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
            getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
            logout: () => {
                auth0Client.logout({
                    logoutParams: {
                        returnTo: location.origin,
                    },
                });
            },
        };
    }
    return {
        isAuthenticated: false,
        user: null,
        loading: initAuth0Status.isRunning || initAuth0Status.neverRun,
        loginWithRedirect: (...p) => {
            if (auth0Client == null) {
                throw Error("The Auth0 client is not yet initialized.");
            }
            return auth0Client.loginWithRedirect(...p);
        },
        getTokenSilently: () => Promise.resolve(undefined),
        getTokenWithPopup: () => Promise.reject(),
        logout: () => undefined,
    };
};

export const [Auth0Provider, useAuth0Context] = constate(useAuth0);
