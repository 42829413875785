import * as React from "react";
import constate from "constate";
import { IApiServiceError } from "@/services/core";

type LoggingTypes = IApiServiceError | Error | string;
type NullableLoggingTypes = LoggingTypes | null | undefined;

interface LoggingOptions {
    genericMessage?: string;
}

export const useLogging = (errors: NullableLoggingTypes[], options?: LoggingOptions) => {
    const loggingContext = useLoggingContext();

    React.useEffect(() => {
        // Create the errors.
        const ids: number[] = [];
        const filteredArray = errors.filter((error) => error != null);

        if (options?.genericMessage != null && filteredArray.length > 0) {
            ids.push(loggingContext.writeError(options.genericMessage));
        } else {
            filteredArray.forEach((error) => {
                if (error != null) {
                    if (typeof (error) === "string") {
                        ids.push(loggingContext.writeError(error));
                    } else if (typeof (error) === "object") {
                        if (Object.prototype.hasOwnProperty.call(error, "errorType") != null) {
                            const e = error as IApiServiceError;
                            ids.push(loggingContext.writeError(e.message));
                        } else if (Object.prototype.hasOwnProperty.call(error, "message") != null) {
                            const e = error as Error;
                            ids.push(loggingContext.writeError(e.message));
                        }
                    }
                }
            });
        }

        // Unregister the errors
        return () => {
            ids.forEach(loggingContext.remove);
        };
    }, [options?.genericMessage, ...errors]);
};

const useLoggingContextBuilder = () => {
    const [errors, setErrors] = React.useState<{ id: number, error: { message: string } }[]>([]);
    const findId = (collection: typeof errors) => (collection.length > 0 ? collection[collection.length - 1].id + 1 : 1);

    const writeError = (message: string): number => {
        let id = 1;
        setErrors((oldErrors) => [...errors, { id: (id = findId(oldErrors)), error: { message } }]);
        return id;
    };

    const remove = (id: number) => {
        setErrors((e) => {
            const errorCollection = e.filter((error) => error.id !== id);
            if (errorCollection.length !== e.length) {
                return errorCollection;
            }
            return e;
        });
    };

    const clear = () => {
        if (errors.length === 0) {
            return;
        }
        setErrors([]);
    };

    const errorOutput = React.useMemo(() => errors.map((error) => error.error.message).filter((item, index, col) => col.indexOf(item) === index), [errors]);

    return {
        writeError,
        remove,
        clear,
        errors: errorOutput,
    };
};

export const [LoggingProvider, useLoggingContext] = constate(useLoggingContextBuilder);

export const withLoggingBoundary = <P extends object>(Component: React.FC<P>): React.FC<P> => (props: P): JSX.Element => (
    <LoggingProvider>
        <Component {...props} />
    </LoggingProvider>
);
