import React from "react";

interface IProps {
    condition: boolean;
    children?: React.ReactNode;
}

export const When: React.FC<IProps> = (props) => {
    if (!props.condition) {
        return null;
    }
    return (<>{props.children}</>);
};
