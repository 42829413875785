import * as React from "react";
import { Icon } from "semantic-ui-react";
import { EuropeanUnionDto } from "@/services/dataTransferObjects";

interface IProps {
    europeanUnion: EuropeanUnionDto[];
}

export const CountryEUStatus: React.FC<IProps> = (props) => (
    <>{props.europeanUnion[props.europeanUnion.length - 1]?.isMember ? <Icon name="check circle" color="green" size="big" /> : <Icon name="close" color="red" size="big"/>}</>
);
