import config from "@/config";
import { TimeZoneDto } from "@/services/dataTransferObjects";
import { useApiQuery, useApiService, UseQueryOptions } from "./core";

export const timeZonesApi = {
    useReadAll(options?: UseQueryOptions<TimeZoneDto[]>) {
        const api = useApiService();
        return useApiQuery(["timezones", "list"], () => (
            api.get<TimeZoneDto[]>(`${config.athenaUrl}/api/timezones`)
        ), options);
    },
};
